import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { DeviceService, KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { IMPORT_LABEL, VALIDATOR_TYPE, CREATE_LABEL, ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import {
  FORM_SELECT_OPTIONS,
  KEY_ALGORITHM_OPTIONS,
  KEY_ALGORITHM_VALUES,
  KEY_CURVE_OPTIONS,
  KEY_CURVE_TYPE_VALUES,
  KEY_SOURCE_OPTIONS,
  KEY_SOURCE_TYPE_VALUES,
  KEY_TYPE_OPTIONS,
  KEY_TYPE_VALUES,
  RSA_SIZE_OPTIONS,
  SELECT_OPTIONS_VALUES,
} from '../device-crypto-assets.config';
import { KEYRING_TYPES, KEYRING_TYPE_VALUES, MICROSEC_DEFAULT_KEYRING, PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { KEY_MANAGEMENT_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  OPTION_SELECT: 'option_select',
  // --------- IMPORT OPTION----------
  KEY_FILE: 'pem_file',
  KEY: 'key_pem',
  KEY_PASSWORD: 'key_password',
  // ---------- CREATE OPTION ------------
  KEY_SOURCE: 'source',
  KEYRING_ID: 'keyring_id',
  LABEL: 'label',
  TYPE: 'type',
  KEY_ALGORITHM: 'algorithm',
  KEY_SIZE: 'size',
  KEY_CURVE: 'curve',

  KEY_DESTINATION: 'destination',
  KEY_PATH: 'file_path',
  KEY_PURPOSE: 'purpose',
  KEY_PURPOSE_ALGORITHM: 'key_purpose_algorithm',
  // Miscellaneous
  ORGANIZATION_ID: 'organization_id',
  PROJECT_ID: 'project_id',
  KEY_PATH_HINT: 'key_path_hint',
};

@Component({
  selector: 'app-dca-create-import-key-form',
  templateUrl: './dca-create-import-key-form.component.html',
  styleUrls: ['./dca-create-import-key-form.component.scss'],
})
export class DcaCreateImportKeyFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  device: any = null;

  keyPurposeAlgorithm: any = null;

  FORM_PARAMS = FORM_PARAMS;

  SELECT_OPTIONS_VALUES = SELECT_OPTIONS_VALUES;

  IMPORT_LABEL = IMPORT_LABEL;

  CREATE_LABEL = CREATE_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private deviceSrv: DeviceService,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    this.device = this.dialogConfig.data?.device;
    this.keyPurposeAlgorithm = this.dialogConfig.data?.keyPurposeAlgorithm;
    await this.prepareConfigs();
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const keyringField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEYRING_ID,
      label: 'KMS Keyring',
      field: 'dropdown',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New Keyring',
          styleClass: 'p-button-success',
          command: () => {
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE}` +
                `/${KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE}`,
              '_blank',
            );
          },
        },
      ] as any[],
      placeholder: 'Select a keyring',
      fieldInfo: 'Keyring',
      defaultValue: null,
      required: false,
      hidden: true,
    } as FormItem);

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        field: 'text',
        labelStyleClass: 'font-bold',
        label: 'Create or import a crypto key to the device.',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.OPTION_SELECT,
        hasNoLabel: true,
        layout: 'horizontal',
        field: 'radio',
        options: this.util.cloneObjectArray(FORM_SELECT_OPTIONS),
        defaultValue: SELECT_OPTIONS_VALUES.CREATE,
        required: true,
        focused: true,
      } as FormItem),
      // --------------------------------------------
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_SOURCE,
        label: 'Key Source',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEY_SOURCE_OPTIONS),
        defaultValue: KEY_SOURCE_TYPE_VALUES.DEVICE,
        fieldInfo: 'Key Server Source',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_DESTINATION,
        label: 'Key Destination',
        field: 'dropdown',
        fieldInfo: 'Key Destination',
        options: this.util.cloneObjectArray(KEYRING_TYPES),
        defaultValue: KEYRING_TYPE_VALUES.FILESYSTEM,
        required: true,
      } as FormItem),
      keyringField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Key Label',
        field: 'input',
        fieldInfo: 'Key label will also be used as filename',
        placeholder: 'key-1',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_FILE,
        label: 'File',
        field: 'file',
        fieldInfo: 'Key Field',
        uploadEvent: new EventEmitter(),
        required: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_PASSWORD,
        label: 'Key Password',
        field: 'password',
        fieldInfo: 'Key Password',
        required: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Type',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEY_TYPE_OPTIONS),
        filter: true,
        required: true,
        fieldInfo: 'Key Type',
        defaultValue: KEY_TYPE_VALUES.ASYMMETRIC,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_ALGORITHM,
        label: 'Algorithm',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEY_ALGORITHM_OPTIONS),
        fieldInfo: 'Key algorithm',
        required: true,
        defaultValue: KEY_ALGORITHM_VALUES.RSA,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_SIZE,
        label: 'Key Size',
        field: 'dropdown',
        options: this.util.cloneObjectArray(RSA_SIZE_OPTIONS),
        defaultValue: 2048,
        fieldInfo: 'Key Size',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_CURVE,
        label: 'Key Curve',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEY_CURVE_OPTIONS),
        defaultValue: KEY_CURVE_TYPE_VALUES.PRIME256,
        fieldInfo: 'Key Curve',
        required: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_PURPOSE,
        label: 'Purpose',
        field: 'dropdown',
        options: (
          Object.keys(this.keyPurposeAlgorithm).map((key) => {
            return { label: key, value: key };
          }) as any[]
        ).filter((option) => (Object.keys(this.keyPurposeAlgorithm[option.value]?.algorithms || {}) as any[]).length > 0),
        placeholder: 'Select Key Purpose',
        fieldInfo: 'Key Purpose',
        required: true,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_PURPOSE_ALGORITHM,
        label: 'Key Purpose Algorithm',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a key purpose algorithm',
        fieldInfo: 'Key purpose algorithm',
        hidden: true,
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_PATH,
        label: 'Key Destination Directory',
        field: 'input',
        fieldInfo: 'Path to be used for key file generation; e.g. /tmp/, /tmp or /usr/share/ca-certificates/mozilla/',
        defaultValue: '/tmp/',
        placeholder: '/tmp',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_PATH_HINT,
        field: 'text',
        hidden: true,
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    keyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });
    this.fields = fields;
    this.setupUploadEvent();
    setTimeout(() => {
      // Setup change events
      this.setupEvents();
      setTimeout(() => {
        this.getKeyrings();
      });
    });
  }

  /**
   * Setup CSR file upload event
   */
  setupUploadEvent() {
    const fileField = this.fields.find((p) => p.name === FORM_PARAMS.KEY_FILE);
    if (!!fileField) {
      fileField.uploadEvent?.subscribe((event: any) => {
        this.form.isLoading = true;
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.KEY_FILE, file);
          const reader = new FileReader();
          reader.onload = () => {
            this.form.isLoading = false;
            this.form.setControlValue(FORM_PARAMS.KEY, reader.result?.toString());
          };
          reader.readAsText(file);
        } else {
          this.form.isLoading = false;
          this.form.setControlValue(FORM_PARAMS.KEY_FILE, null);
          this.form.setControlValue(FORM_PARAMS.KEY, null);
        }
      });
    }
  }

  setDefaultCreateValues() {
    this.form.setControlValue(FORM_PARAMS.KEY_DESTINATION, KEYRING_TYPE_VALUES.FILESYSTEM);
    this.form.setControlValue(FORM_PARAMS.KEY_SOURCE, KEY_SOURCE_TYPE_VALUES.DEVICE);
    this.form.setControlValue(FORM_PARAMS.KEY_ALGORITHM, KEY_ALGORITHM_VALUES.RSA);
  }

  setDefaultImportValues() {
    this.form.setControlValue(FORM_PARAMS.KEY_DESTINATION, KEYRING_TYPE_VALUES.FILESYSTEM);
  }

  /**
   * Setup Change Event
   */
  setupEvents() {
    this.form.setChangeEvent(FORM_PARAMS.OPTION_SELECT, (option) => {
      const importValidator = option === SELECT_OPTIONS_VALUES.IMPORT ? [VALIDATOR_TYPE.REQUIRED] : [];
      const createValidator = option === SELECT_OPTIONS_VALUES.CREATE ? [VALIDATOR_TYPE.REQUIRED] : [];

      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_FILE, importValidator);
      this.form.setControlVisibility(FORM_PARAMS.KEY_PASSWORD, option === SELECT_OPTIONS_VALUES.IMPORT);

      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_SOURCE, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEYRING_ID, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.TYPE, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_ALGORITHM, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_SIZE, createValidator);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_CURVE, createValidator);

      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE, []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, []);

      // Set to default values:
      if (option === SELECT_OPTIONS_VALUES.CREATE) {
        this.setDefaultCreateValues();
      }
      if (option === SELECT_OPTIONS_VALUES.IMPORT) {
        this.setDefaultImportValues();
      }
      this.form.getControl(FORM_PARAMS.KEY_DESTINATION).enable();
    });
    this.form.setChangeEvent(FORM_PARAMS.KEY_DESTINATION, (option) => {
      const mode = this.form.getControlValue(FORM_PARAMS.OPTION_SELECT);
      switch (option) {
        case KEYRING_TYPE_VALUES.FILESYSTEM: {
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PATH, [VALIDATOR_TYPE.REQUIRED]);
          if (this.form.getControlValue(FORM_PARAMS.KEY_SOURCE) !== KEY_SOURCE_TYPE_VALUES.SERVER) {
            this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE, []);
            this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, []);
          }
          if (mode == SELECT_OPTIONS_VALUES.CREATE) {
            const algoField = this.fields.find((field) => field.name === FORM_PARAMS.KEY_ALGORITHM);
            if (!!algoField) {
              algoField.options = this.util.cloneObjectArray(KEY_ALGORITHM_OPTIONS);
            }
            this.form.setControlValue(FORM_PARAMS.KEY_ALGORITHM, KEY_ALGORITHM_VALUES.RSA);
          }
          break;
        }
        case KEYRING_TYPE_VALUES.CRYPTOKEN: {
          this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PATH, []);
          if (this.form.getControlValue(FORM_PARAMS.KEY_SOURCE) !== KEY_SOURCE_TYPE_VALUES.SERVER) {
            this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE, []);
            this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, []);
          }
          if (mode == SELECT_OPTIONS_VALUES.CREATE) {
            const algoField = this.fields.find((field) => field.name === FORM_PARAMS.KEY_ALGORITHM);
            if (!!algoField) {
              algoField.options = algoField.options.filter((option) => option.value === KEY_ALGORITHM_VALUES.RSA);
            }
            this.form.setControlValue(FORM_PARAMS.KEY_ALGORITHM, KEY_ALGORITHM_VALUES.RSA);
          }
          break;
        }
      }
    });
    // Key Purpose
    this.form.setChangeEvent(FORM_PARAMS.KEY_PURPOSE, (purpose) => {
      this.form.setControlVisibility(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, true);
      this.form.setControlValue(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, null);
      const algorithmField = this.fields.find((p) => p.name === FORM_PARAMS.KEY_PURPOSE_ALGORITHM);
      if (!!algorithmField) {
        algorithmField.options = Object.entries((this.keyPurposeAlgorithm[purpose]?.algorithms as any) || {})
          // For Crypto Assets Keyrings are always from Filesystem only (Filtered)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_key, algo]: [string, any]) => {
            return !!(algo.supported_keyring_types as string[]).includes(KEYRING_TYPE_VALUES.FILESYSTEM);
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .map(([key, _algo]: [string, any]) => {
            return { label: key, value: key };
          });
      }
    });
    this.form.setChangeEvent(FORM_PARAMS.KEY_SOURCE, (option) => {
      // Do not allow user to select Cryptoken destination if sey source is server
      if (option === KEY_SOURCE_TYPE_VALUES.SERVER) {
        this.form.setControlValue(FORM_PARAMS.KEY_DESTINATION, KEYRING_TYPE_VALUES.FILESYSTEM);
        this.form.getControl(FORM_PARAMS.KEY_DESTINATION).disable();
      } else {
        this.form.getControl(FORM_PARAMS.KEY_DESTINATION).enable();
      }
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEYRING_ID, option === KEY_SOURCE_TYPE_VALUES.SERVER ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE, option === KEY_SOURCE_TYPE_VALUES.SERVER ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.TYPE, option === KEY_SOURCE_TYPE_VALUES.DEVICE ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.KEY_ALGORITHM,
        option === KEY_SOURCE_TYPE_VALUES.DEVICE ? [VALIDATOR_TYPE.REQUIRED] : [],
      );
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_SIZE, option === KEY_SOURCE_TYPE_VALUES.DEVICE ? [VALIDATOR_TYPE.REQUIRED] : []);
      if (option === KEY_SOURCE_TYPE_VALUES.DEVICE) {
        this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, []);
      } else {
        this.form.setControlValidators(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, [VALIDATOR_TYPE.REQUIRED]);
      }
      this.form.setControlValue(FORM_PARAMS.KEY_PURPOSE, null);
      this.form.setControlValue(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, null);
      this.form.setControlVisibility(FORM_PARAMS.KEY_PURPOSE_ALGORITHM, false);
    });
    this.form.setChangeEvent(FORM_PARAMS.KEY_ALGORITHM, (option) => {
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_SIZE, option === KEY_ALGORITHM_VALUES.RSA ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_CURVE, option === KEY_ALGORITHM_VALUES.ECC ? [VALIDATOR_TYPE.REQUIRED] : []);
    });
    this.form.setChangeEvent(FORM_PARAMS.LABEL, (value) => {
      const keyDirectory = this.form.getControlValue(FORM_PARAMS.KEY_PATH);
      this.populateHintField(value, keyDirectory);
    });
    this.form.setChangeEvent(FORM_PARAMS.KEY_PATH, (value) => {
      const keyLabel = this.form.getControlValue(FORM_PARAMS.LABEL);
      this.populateHintField(keyLabel, value);
    });
  }

  private populateHintField(keyLabel: string, directoryLabel: string) {
    const keyDestination = this.form.getControlValue(FORM_PARAMS.KEY_DESTINATION);
    const hintField = this.fields.find((p) => p.name === FORM_PARAMS.KEY_PATH_HINT);
    this.form.setControlVisibility(
      FORM_PARAMS.KEY_PATH_HINT,
      !!hintField && !!directoryLabel && !!keyLabel && keyDestination === KEYRING_TYPE_VALUES.FILESYSTEM,
    );
    if (!!hintField) {
      hintField.label =
        !!keyLabel && !!directoryLabel
          ? `The full key path is ${directoryLabel}${directoryLabel.slice(-1) !== '/' ? '/' : ''}${keyLabel}.key.pem`
          : '';
    }
  }

  /**
   * Import CA certificate
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.getPayload();
    this.form.isLoading = true;
    const importForm = this.form.getControlValue(FORM_PARAMS.OPTION_SELECT) === SELECT_OPTIONS_VALUES.IMPORT;
    const observable = !!importForm
      ? this.deviceSrv.importDeviceCryptoAssetKey(this.device.id, payload)
      : this.deviceSrv.createDeviceCryptoAssetKey(this.device.id, payload);

    observable
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(
            importForm ? `Key import is being processed.` : 'Key creation is being initiated. Check the events tab for details.',
          );
          closeDialog();
        },
        error: (err) => {
          if (!!err?.error?.errors?.[FORM_PARAMS.KEY]) {
            const errors = err.error.errors;
            errors[FORM_PARAMS.KEY_FILE] = errors?.[FORM_PARAMS.KEY];
            this.form.showServerErrorMessage(err);
          }
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * modify payload
   */
  getPayload() {
    const payload = this.form.getRawValue();

    delete payload[FORM_PARAMS.OPTION_SELECT];
    delete payload[FORM_PARAMS.KEY_FILE];
    delete payload[FORM_PARAMS.KEY_PATH_HINT];

    const destination = this.form.getControlValue(FORM_PARAMS.KEY_DESTINATION);

    if (destination === KEYRING_TYPE_VALUES.CRYPTOKEN) {
      delete payload[FORM_PARAMS.KEY_PATH];
    }

    switch (this.form.getControlValue(FORM_PARAMS.OPTION_SELECT)) {
      case SELECT_OPTIONS_VALUES.CREATE: {
        const keySource = this.form.getControlValue(FORM_PARAMS.KEY_SOURCE);
        const keyAlgo = this.form.getControlValue(FORM_PARAMS.KEY_ALGORITHM);

        if (keySource === KEY_SOURCE_TYPE_VALUES.DEVICE) {
          delete payload[FORM_PARAMS.KEYRING_ID];
          delete payload[FORM_PARAMS.KEY_PURPOSE];
        }
        if (keySource === KEY_SOURCE_TYPE_VALUES.SERVER) {
          payload[FORM_PARAMS.KEY_ALGORITHM] = payload[FORM_PARAMS.KEY_PURPOSE_ALGORITHM];
          delete payload[FORM_PARAMS.KEY_SIZE];
          delete payload[FORM_PARAMS.TYPE];
        }

        delete payload[FORM_PARAMS.KEY];
        delete payload[FORM_PARAMS.KEY_PASSWORD];
        delete payload[FORM_PARAMS.KEY_PURPOSE_ALGORITHM];

        switch (keyAlgo) {
          case KEY_ALGORITHM_VALUES.ECC: {
            delete payload[FORM_PARAMS.KEY_SIZE];
            break;
          }
          case KEY_ALGORITHM_VALUES.RSA: {
            delete payload[FORM_PARAMS.KEY_CURVE];
            break;
          }
        }
        break;
      }
      case SELECT_OPTIONS_VALUES.IMPORT: {
        delete payload[FORM_PARAMS.KEY_SOURCE];
        delete payload[FORM_PARAMS.KEYRING_ID];
        delete payload[FORM_PARAMS.TYPE];
        delete payload[FORM_PARAMS.KEY_ALGORITHM];
        delete payload[FORM_PARAMS.KEY_SIZE];
        delete payload[FORM_PARAMS.KEY_CURVE];
        delete payload[FORM_PARAMS.KEY_PURPOSE];
        delete payload[FORM_PARAMS.KEY_PURPOSE_ALGORITHM];
        break;
      }
      default: {
        break;
      }
    }

    return payload;
  }

  /**
   * Get Keyrings and Populate Keyring Field on Form
   */
  getKeyrings() {
    this.form.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const keyringField = this.fields.find((field) => field.name === FORM_PARAMS.KEYRING_ID);
          if (!!keyringField) {
            keyringField.options = this.util.sortObjectArray(
              (
                rs?.data.filter(
                  (keyring: any) => keyring.type === KEYRING_TYPE_VALUES.FILESYSTEM && keyring.name !== MICROSEC_DEFAULT_KEYRING,
                ) as any[]
              ).map((p: any) => ({
                value: p.id,
                label: p.name,
              })),
              'label',
            );
            if (!keyringField.options.find((p) => p.value === this.form.getControlValue(FORM_PARAMS.KEYRING_ID))) {
              this.form.setControlValue(FORM_PARAMS.KEYRING_ID, null);
            }
          }
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
