import { faKey, faTelevision } from '@fortawesome/free-solid-svg-icons';
import { FEATURES, PROJECT_MANAGEMENT_GROUPS, SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { WorkflowsComponent } from './overview/workflows/workflows.component';
import { WORKFLOWS_CHILDREN } from '@microsec/module-workflows';
import { KeyManagementComponent } from './settings/key-management/key-management.component';
import { KEY_MANAGEMENT_CHILDREN } from './settings/key-management/key-management.config';

export const PROJECT_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    group: PROJECT_MANAGEMENT_GROUPS.OVERVIEW,
    route: PROJECT_MANAGEMENT_CONSTANTS.WORKFLOWS.ROUTE,
    icon: faTelevision,
    label: PROJECT_MANAGEMENT_CONSTANTS.WORKFLOWS.LABEL,
    component: WorkflowsComponent,
    children: WORKFLOWS_CHILDREN,
    featureName: FEATURES.SYSTEM_MANAGEMENT,
    featureCheckField: SYSTEM_MANAGEMENT_FEATURES.WORKFLOW,
    visible: false,
  },
  {
    group: PROJECT_MANAGEMENT_GROUPS.SETTINGS,
    route: PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE,
    icon: faKey,
    label: PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.LABEL,
    component: KeyManagementComponent,
    children: KEY_MANAGEMENT_CHILDREN,
  },
  // {
  //   group: PROJECT_MANAGEMENT_GROUPS.SETTINGS,
  //   route: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
  //   icon: faUsers,
  //   label: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.LABEL,
  //   component: ProjectSettingsComponent,
  //   children: PROJECT_SETTINGS_CHILDREN,
  // },
];
