@if (!isLoading) {
  <div class="flex flex-column">
    <!-- Banner -->
    @if (!!showBanner) {
      <div class="banner-wrapper">
        <div class="w-full box flex flex-row justify-content-between text-data">
          <div class="content flex align-items-center">
            <i class="icon text-4xl fas fa-circle-info px-6"></i>
            <div class="message text-base flex flex-column">
              <div class="title text-3xl pb-1 mb-2">Get started with LCMS RA</div>
              <div class="message text-base mb-2">Get started protecting your devices and networks by following these steps:</div>
              <div class="flex flex-column message text-base text-sm">
                @for (c of bannerCheckboxContent; track $index) {
                  <div class="flex flex-row mb-2">
                    <i
                      class="fa fa-circle-check mb-1 mr-2"
                      [ngClass]="{ 'fa-regular': !c.checked }"
                      [ngStyle]="{ color: c.checked ? '#00ad74' : '#444444' }"></i>
                    <span>{{ c.label }}</span>
                  </div>
                }
              </div>
              <div class="message text-base mt-2">For more information, refer to the documentation.</div>
            </div>
          </div>
          <div class="flex justify-content-end mt-4 mr-6" (click)="closeBanner()">
            <i class="fa fa-close text-3xl banner-close-icon"></i>
          </div>
        </div>
      </div>
    }

    <!-- Refresh Button -->
    <div class="text-right mb-2">
      <microsec-refresh-button [lastRefresh]="summaryLastRefresh$ | async" (refreshEvent)="refresh()" />
    </div>
    <!-- Page Main Content -->
    <router-outlet />
  </div>
} @else {
  <microsec-loading-block />
}
