import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@lcms-shared';
import { SharedCertificateDetailsModule } from '../shared-components/shared-certificate-details/shared-certificate-details.module';
import { SharedLicenseWarningModule } from '../shared-components/shared-license-warning/shared-license-warning.module';
import { SharedProjectFormModule } from '../shared-components/shared-project-form/shared-project-form.module';
import { SharedUserAccessModule } from '../shared-components/shared-user-access/shared-user-access.module';
import { SharedEventsModule } from '../shared-components/shared-events/shared-events.module';
import { WorkflowsComponent } from './overview/workflows/workflows.component';
import { ProjectManagementComponent } from './project-management.component';
import { ProjectSettingsComponent } from './settings/project-settings/project-settings.component';

// Angular Shared Libraries
import { WorkflowsModule } from '@microsec/module-workflows';
import { KeyManagementComponent } from './settings/key-management/key-management.component';
import { CryptokenManagerFormComponent } from './settings/key-management/cryptoken-managers/cryptoken-manager-form/cryptoken-manager-form.component';
import { CryptokenManagerInfoComponent } from './settings/key-management/cryptoken-managers/cryptoken-manager-info/cryptoken-manager-info.component';
import { CryptokenFormComponent } from './settings/key-management/cryptoken-managers/cryptoken-manager-info/cryptoken-manager-overview/cryptoken-form/cryptoken-form.component';
import { CryptokenManagerOverviewComponent } from './settings/key-management/cryptoken-managers/cryptoken-manager-info/cryptoken-manager-overview/cryptoken-manager-overview.component';
import { CryptokenManagersComponent } from './settings/key-management/cryptoken-managers/cryptoken-managers.component';
import { KeyringFormComponent } from './settings/key-management/keyring/keyring-form/keyring-form.component';
import { GenericKeyDetailsComponent } from './settings/key-management/keyring/keyring-info/keyring-generic-keys/generic-key-details/generic-key-details.component';
import { GenericKeyFormComponent } from './settings/key-management/keyring/keyring-info/keyring-generic-keys/generic-key-form/generic-key-form.component';
import { GenericKeyImportFormComponent } from './settings/key-management/keyring/keyring-info/keyring-generic-keys/generic-key-import-form/generic-key-import-form.component';
import { KeyringGenericKeysComponent } from './settings/key-management/keyring/keyring-info/keyring-generic-keys/keyring-generic-keys.component';
import { GpgKeyDetailsComponent } from './settings/key-management/keyring/keyring-info/keyring-gpg-keys/gpg-key-details/gpg-key-details.component';
import { GpgKeyExportFormComponent } from './settings/key-management/keyring/keyring-info/keyring-gpg-keys/gpg-key-export-form/gpg-key-export-form.component';
import { GpgKeyFormComponent } from './settings/key-management/keyring/keyring-info/keyring-gpg-keys/gpg-key-form/gpg-key-form.component';
import { GpgKeyImportFormComponent } from './settings/key-management/keyring/keyring-info/keyring-gpg-keys/gpg-key-import-form/gpg-key-import-form.component';
import { KeyringGpgKeysComponent } from './settings/key-management/keyring/keyring-info/keyring-gpg-keys/keyring-gpg-keys.component';
import { KeyringInfoComponent } from './settings/key-management/keyring/keyring-info/keyring-info.component';
import { KeyringOverviewComponent } from './settings/key-management/keyring/keyring-info/keyring-overview/keyring-overview.component';
import { KmsComponent } from './settings/key-management/keyring/kms.component';
import { UserAccessComponent } from './settings/project-settings/user-access/user-access.component';

@NgModule({
  declarations: [
    ProjectManagementComponent,
    // #region ----- OVERVIEW -----
    // Workflows
    WorkflowsComponent,

    // #region ----- KEY MANAGEMENT -----
    KeyManagementComponent,
    // Cryptoken manager
    CryptokenManagersComponent,
    CryptokenManagerFormComponent,
    CryptokenManagerInfoComponent,
    CryptokenManagerOverviewComponent,
    CryptokenFormComponent,
    // KMS
    KmsComponent,
    KeyringFormComponent,
    KeyringInfoComponent,
    KeyringOverviewComponent,
    // - Generic Keys
    KeyringGenericKeysComponent,
    GenericKeyFormComponent,
    GenericKeyImportFormComponent,
    GenericKeyDetailsComponent,
    // - GPG Keys
    KeyringGpgKeysComponent,
    GpgKeyFormComponent,
    GpgKeyImportFormComponent,
    GpgKeyExportFormComponent,
    GpgKeyDetailsComponent,

    // #region ----- PROJECT SETTINGS -----
    ProjectSettingsComponent,

    // User Access
    UserAccessComponent,
    // #endregion
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedProjectFormModule,
    SharedCertificateDetailsModule,
    SharedUserAccessModule,
    SharedLicenseWarningModule,
    SharedEventsModule,
    // Angular Shared Libraries
    WorkflowsModule,
  ],
})
export class ProjectManagementModule {}
