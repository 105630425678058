import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import {
  CA_MANAGER_TYPES,
  CA_MANAGER_TYPE_VALUES,
  KEYRING_TYPE_VALUES,
  MICROSEC_DEFAULT_KEYRING,
  PKI_MANAGEMENT_FEATURES,
  PROJECT_MANAGEMENT_CONSTANTS,
} from '@lcms-constants';
import { CaManagementService, KmsService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import {
  CREATE_LABEL,
  CREATE_SUCCESS,
  MEDIUM_TEXT_MAX_LENGTH,
  ORGANIZATION_LEVEL_ROUTE,
  PROJECT_LEVEL_ROUTE,
  SAVE_CHANGES_LABEL,
  UPDATE_SUCCESS,
  VALIDATOR_TYPE,
} from '@microsec/constants';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { KEY_MANAGEMENT_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  ID: 'id',
  TYPE: 'type',
  NAME: 'name',
  DESCRIPTION: 'description',
  ADDRESS: 'address',
  ACCESS_TOKEN: 'token',
  IS_SSL: 'is_ssl',
  SSL_CERTIFICATE_FILE: 'ssl_certificate_file',
  SSL_CERTIFICATE: 'ssl_certificate',
  USERNAME: 'username',
  PKCS12_FILE: 'pkcs12_file',
  PKCS12: 'pkcs12',
  CERTIFICATE_PROFILE: 'certificate_profile_name',
  KEYRING_ID: 'keyring_id',
  // ----- Decorator ----
  EDIT_HINT: 'edit_hint',
  HINT_DIVIDER: 'hint_divider',
};

const ADDRESS_REGEX = /(http:\/\/|https:\/\/)([-a-zA-Z0-9.]){1,256}:([0-9]{1,5})(\/.*)?$/;

@Component({
  selector: 'app-ca-connection-form',
  templateUrl: './ca-connection-form.component.html',
  styleUrls: ['./ca-connection-form.component.scss'],
})
export class CaConnectionFormComponent extends BaseComponent implements OnInit {
  isLoading = false;

  caManager: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  CREATE_LABEL = CREATE_LABEL;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  accessTokenLabel = 'Access Token';

  accessTokenInfo = 'Access token can be generated from User Settings > Access Tokens from LCMS-CA';

  editHintLabelTemplate = 'You can change the {0}. If left blank, {0} will remain unchanged.';

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private caManagementSrv: CaManagementService,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.caManager = this.dialogConfig?.data?.caManager;
    this.initForm();
    this.getKeyrings();
    if (!!this.caManager) {
      this.form.patchValue(this.caManager);
    }
  }

  /**
   * Initialize form
   */
  initForm() {
    const hasInbuiltCa = this.dialogConfig?.data?.hasInBuiltCa || !this.isX509Featured;
    // Keyring ID
    const keyringField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEYRING_ID,
      label: 'KMS Keyring',
      field: 'dropdown',
      placeholder: 'Select a keyring',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New Keyring',
          styleClass: 'p-button-success',
          command: () => {
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE}` +
                `/${KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE}`,
              '_blank',
            );
          },
        },
      ] as any[],
      defaultValue: null,
      fieldInfo: 'Select Filesystem Type KMS Keyring',
      showRequiredMark: true,
      required: !!hasInbuiltCa,
      hidden: !!hasInbuiltCa,
    } as FormItem);

    // Access Token
    const accessTokenField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.ACCESS_TOKEN,
      label: this.accessTokenLabel,
      field: 'password',
      required: !!hasInbuiltCa,
      fieldInfo: this.accessTokenInfo,
      feedback: false,
      defaultValue: '',
      hidden: !hasInbuiltCa,
    } as FormItem);
    // PKCS #12
    const pkcs12hFileField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.PKCS12_FILE,
      label: 'PKCS #12',
      field: 'file',
      fieldInfo: 'PKCS #12',
      defaultValue: null,
      uploadEvent: new EventEmitter(),
      hidden: true,
    } as FormItem);
    const pkcs12hField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.PKCS12,
      defaultValue: null,
      hidden: true,
    } as FormItem);
    // Certificate Profile
    const certificateProfileField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.CERTIFICATE_PROFILE,
      label: 'Certificate Profile',
      field: 'input',
      fieldInfo: 'Certificate profile',
      hidden: !this.caManager || (!!this.caManager && this.caManager?.[FORM_PARAMS.TYPE] !== CA_MANAGER_TYPE_VALUES.EJBCA),
      defaultValue: 'ENDUSER',
    } as FormItem);
    // Is SSL
    const isSSLField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.IS_SSL,
      label: 'Is SSL',
      field: 'checkbox',
      fieldInfo: 'Is SSL',
      defaultValue: false,
      hidden: !hasInbuiltCa,
    } as FormItem);
    // SSL Certificate File
    const sslCertificateFileField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.SSL_CERTIFICATE_FILE,
      label: 'Certificate',
      field: 'file',
      fieldInfo: 'SSL Certificate',
      uploadEvent: new EventEmitter(),
      hidden: !this.caManager || !this.caManager?.[FORM_PARAMS.IS_SSL],
    } as FormItem);
    // SSL Certificate
    const sslCertificateField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.SSL_CERTIFICATE,
      defaultValue: null,
      hidden: true,
    } as FormItem);
    // Edit Decor Fields
    const editHintField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.EDIT_HINT,
      label: this.editHintLabelTemplate.split('{0}').join(this.accessTokenLabel.toLowerCase()),
      field: 'text',
      hidden: !this.caManager,
    } as FormItem);
    const editDecorFields = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.HINT_DIVIDER,
        field: 'divider',
        hidden: !this.caManager,
      } as FormItem),
      editHintField,
    ];

    const fields = [
      Object.assign(new FormItem(), {
        label: 'Connect to CAs to facilitate device enrollments and to establish secure connections.',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Type',
        field: 'dropdown',
        options: this.util
          .cloneObjectArray(CA_MANAGER_TYPES)
          .filter((type) => (!!hasInbuiltCa ? type.value !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA : true))
          .filter((option) => (!this.isExternalCAFeatured ? option.value === CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA : true)),
        required: true,
        showRequiredMark: true,
        fieldInfo: 'Connection type',
        defaultValue: !hasInbuiltCa ? CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA : CA_MANAGER_TYPE_VALUES.MICROSEC_LCMS_CA,
        focused: true,
        hidden: !!this.caManager,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: !!hasInbuiltCa,
        fieldInfo: 'Name of connection',
        defaultValue: '',
        hidden: !hasInbuiltCa,
      } as FormItem),
      keyringField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DESCRIPTION,
        label: 'Description',
        field: 'input',
        required: !!hasInbuiltCa,
        fieldInfo: 'Connection description',
        defaultValue: '',
        hidden: !hasInbuiltCa,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ADDRESS,
        label: 'Address',
        field: 'input',
        required: !!hasInbuiltCa,
        maxLength: MEDIUM_TEXT_MAX_LENGTH,
        pattern: ADDRESS_REGEX,
        patternErrorText: 'Please enter the address in the correct format, e.g. https://microsec-ca.usec.io:8443',
        fieldInfo: 'Address',
        defaultValue: '',
        hidden: !hasInbuiltCa,
      } as FormItem),
      //  EJBCA
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USERNAME,
        label: 'Username',
        field: 'input',
        fieldInfo: 'Username',
        defaultValue: '',
        hidden: !this.caManager || (!!this.caManager && this.caManager?.[FORM_PARAMS.TYPE] !== CA_MANAGER_TYPE_VALUES.EJBCA),
      } as FormItem),
      ...(!!this.caManager ? [certificateProfileField] : []),
      // Common fields
      ...(!!this.caManager ? [isSSLField, sslCertificateFileField, sslCertificateField] : []),
      // Microsoft ADCS CA or MicroSec LCMS CA
      ...(!this.caManager ? [accessTokenField] : []),
      ...(!this.caManager ? [] : editDecorFields),
      ...(!!this.caManager ? [accessTokenField] : []),
      // EJBCA
      pkcs12hFileField,
      pkcs12hField,
      ...(!this.caManager ? [certificateProfileField] : []),
      // Common fields
      ...(!this.caManager ? [isSSLField, sslCertificateFileField, sslCertificateField] : []),
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;

    this.setupChangeEvents(accessTokenField, editHintField);
    this.setupSSLCertificateUploadEvent();

    pkcs12hFileField.uploadEvent?.subscribe((event) => {
      this.form.isLoading = true;
      this.getValidatedPKCS12File(event).subscribe((validatedFile: any) => {
        this.form.setControlValue(FORM_PARAMS.PKCS12_FILE, validatedFile);
        this.form.isLoading = false;
      });
    });
    keyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });

    // Set the value of Type Field on initial load to trigger changeEvent callback
    setTimeout(() => {
      this.form.setControlValue(
        FORM_PARAMS.TYPE,
        !hasInbuiltCa ? CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA : CA_MANAGER_TYPE_VALUES.MICROSEC_LCMS_CA,
      );
    }, 200);
  }

  /**
   * Setup change events
   */
  setupChangeEvents(accessTokenField: FormItem, editHintField: FormItem) {
    this.form.setChangeEvent(FORM_PARAMS.TYPE, (type: any) => {
      // Name, description, address and ssl
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.NAME,
        type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA ? [VALIDATOR_TYPE.REQUIRED] : [],
      );
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.DESCRIPTION,
        type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA ? [VALIDATOR_TYPE.REQUIRED] : [],
      );
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.ADDRESS,
        type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA ? [VALIDATOR_TYPE.REQUIRED, VALIDATOR_TYPE.PATTERN] : [],
        type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA ? [null, ADDRESS_REGEX] : [],
      );

      this.form.setControlVisibility(FORM_PARAMS.IS_SSL, type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA);

      // Keyring ID
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.KEYRING_ID,
        type === CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA ? [VALIDATOR_TYPE.REQUIRED] : [],
      );

      // Access token
      accessTokenField.label = type === CA_MANAGER_TYPE_VALUES.EJBCA ? 'Password' : this.accessTokenLabel;
      accessTokenField.fieldInfo = type === CA_MANAGER_TYPE_VALUES.EJBCA ? 'Password' : this.accessTokenInfo;
      this.form.setControlValidators(
        FORM_PARAMS.ACCESS_TOKEN,
        !!this.caManager || type === CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA ? [] : [VALIDATOR_TYPE.REQUIRED],
      );
      this.form.setControlVisibility(FORM_PARAMS.ACCESS_TOKEN, type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA);

      // Username
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.USERNAME, type === CA_MANAGER_TYPE_VALUES.EJBCA ? [VALIDATOR_TYPE.REQUIRED] : []);
      // PKCS #12
      this.form.setControlValidators(
        FORM_PARAMS.PKCS12_FILE,
        type === CA_MANAGER_TYPE_VALUES.EJBCA && !this.caManager ? [VALIDATOR_TYPE.REQUIRED] : [],
      );
      this.form.setControlVisibility(FORM_PARAMS.PKCS12_FILE, type === CA_MANAGER_TYPE_VALUES.EJBCA);
      // Certificate Profile
      this.form.setControlValidatorsAndVisibility(
        FORM_PARAMS.CERTIFICATE_PROFILE,
        type === CA_MANAGER_TYPE_VALUES.EJBCA ? [VALIDATOR_TYPE.REQUIRED] : [],
      );
      // Edit Decor Fields
      if (!!this.caManager) {
        editHintField.label = this.editHintLabelTemplate
          .split('{0}')
          .join(type === CA_MANAGER_TYPE_VALUES.EJBCA ? 'password and PKCS #12' : this.accessTokenLabel.toLowerCase());

        // Remove Certificate, Decor Fields and the divider when switching to In-built CA type in Edit Mode
        if (type === CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA) {
          this.form.setControlVisibility(FORM_PARAMS.EDIT_HINT, false);
          this.form.setControlVisibility(FORM_PARAMS.HINT_DIVIDER, false);
          this.form.setControlVisibility(FORM_PARAMS.SSL_CERTIFICATE_FILE, false);
        }
      }
    });
    this.form.setChangeEvent(FORM_PARAMS.IS_SSL, (value) => {
      this.form.setControlVisibility(FORM_PARAMS.SSL_CERTIFICATE_FILE, !!value);
    });
  }

  /**
   * Validate the file with correct format
   * @param event
   * @returns
   */
  private getValidatedPKCS12File(event: any) {
    return new Observable((observe) => {
      if (!!event.target && !!event.target.files && !!event.target.files.length) {
        const file = (event.target.files as FileList).item(0) as File;
        const reader = new FileReader();
        reader.onload = (loadedFile) => {
          let base64String: string = (loadedFile.target?.result as string) || '';
          if (base64String.indexOf(',') > -1) {
            base64String = base64String.split(',')[1];
          }
          this.form.setControlValue(FORM_PARAMS.PKCS12, base64String);
          observe.next(file);
        };
        reader.readAsDataURL(file);
      } else {
        observe.next(null);
      }
    });
  }

  /**
   * Setup certificate file upload event
   */
  setupSSLCertificateUploadEvent() {
    const sslCertificateFileField = this.fields.find((p) => p.name === FORM_PARAMS.SSL_CERTIFICATE_FILE);
    if (!!sslCertificateFileField) {
      sslCertificateFileField.uploadEvent?.subscribe((event: any) => {
        this.form.isLoading = true;
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.SSL_CERTIFICATE_FILE, file);
          const reader = new FileReader();
          reader.onload = () => {
            this.form.isLoading = false;
            this.form.setControlValue(FORM_PARAMS.SSL_CERTIFICATE, reader.result?.toString());
          };
          reader.readAsText(file);
        } else {
          this.form.isLoading = false;
          this.form.setControlValue(FORM_PARAMS.SSL_CERTIFICATE_FILE, null);
          this.form.setControlValue(FORM_PARAMS.SSL_CERTIFICATE, null);
        }
      });
    }
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.util.cloneDeepObject(this.form.getRawValue());
    payload.project_id = this.breadcrumbConfig?.projectId;
    delete payload[FORM_PARAMS.HINT_DIVIDER];
    delete payload[FORM_PARAMS.EDIT_HINT];
    switch (payload[FORM_PARAMS.TYPE]) {
      case CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA: {
        delete payload[FORM_PARAMS.USERNAME];
        delete payload[FORM_PARAMS.PKCS12];
        delete payload[FORM_PARAMS.CERTIFICATE_PROFILE];
        delete payload[FORM_PARAMS.ACCESS_TOKEN];
        delete payload[FORM_PARAMS.NAME];
        delete payload[FORM_PARAMS.DESCRIPTION];
        delete payload[FORM_PARAMS.ADDRESS];
        delete payload[FORM_PARAMS.IS_SSL];
        delete payload[FORM_PARAMS.SSL_CERTIFICATE];
        break;
      }
      case CA_MANAGER_TYPE_VALUES.MICROSEC_LCMS_CA: {
        delete payload[FORM_PARAMS.KEYRING_ID];
        delete payload[FORM_PARAMS.USERNAME];
        delete payload[FORM_PARAMS.PKCS12];
        delete payload[FORM_PARAMS.CERTIFICATE_PROFILE];
        if (this.caManager && !payload[FORM_PARAMS.ACCESS_TOKEN]) {
          delete payload[FORM_PARAMS.ACCESS_TOKEN];
        }
        break;
      }
      case CA_MANAGER_TYPE_VALUES.MICROSOFT_ADCS_CA: {
        delete payload[FORM_PARAMS.KEYRING_ID];
        delete payload[FORM_PARAMS.USERNAME];
        delete payload[FORM_PARAMS.PKCS12];
        delete payload[FORM_PARAMS.CERTIFICATE_PROFILE];
        break;
      }
      case CA_MANAGER_TYPE_VALUES.EJBCA: {
        delete payload[FORM_PARAMS.KEYRING_ID];
        if (this.caManager && !payload[FORM_PARAMS.ACCESS_TOKEN]) {
          delete payload[FORM_PARAMS.ACCESS_TOKEN];
        }
        break;
      }
      default: {
        break;
      }
    }
    delete payload[FORM_PARAMS.PKCS12_FILE];
    if (!payload[FORM_PARAMS.IS_SSL] || !payload[FORM_PARAMS.SSL_CERTIFICATE_FILE]) {
      delete payload[FORM_PARAMS.SSL_CERTIFICATE];
    }
    delete payload[FORM_PARAMS.SSL_CERTIFICATE_FILE];
    const request: Observable<any> = !this.caManager
      ? this.caManagementSrv.createCAManager(payload)
      : this.caManagementSrv.editCAManager(this.caManager?.id, payload);
    this.isLoading = true;
    request
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          const message = !this.caManager ? CREATE_SUCCESS : UPDATE_SUCCESS;
          this.showSuccessMessage(message.replace('{0}', 'CA manager'));
          closeDialog();
        },
        error: (err) => {
          if (!!err?.error?.errors?.[FORM_PARAMS.SSL_CERTIFICATE]) {
            const errors = err.error.errors;
            errors[FORM_PARAMS.SSL_CERTIFICATE_FILE] = errors?.[FORM_PARAMS.SSL_CERTIFICATE];
          }
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get keyrings
   */
  getKeyrings() {
    this.form.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const keyringField = this.fields.find((p) => p.name === FORM_PARAMS.KEYRING_ID);
          if (!!keyringField) {
            keyringField.options = (
              ((rs?.data as any[]) || []).filter(
                (keyring) => keyring.type === KEYRING_TYPE_VALUES.FILESYSTEM && keyring.name !== MICROSEC_DEFAULT_KEYRING,
              ) as any[]
            ).map((key) => ({
              ...key,
              value: key.id,
              label: key.name,
            }));
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Check if feature for X509 enabled
   */
  get isX509Featured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509);
  }

  /**
   * Check if feature for external CA enabled
   */
  get isExternalCAFeatured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.EXTERNAL_CA);
  }
}
