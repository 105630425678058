import { Component, OnInit } from '@angular/core';
import { MODULE_CONSTANTS, PROJECT_ID_PARAM_ROUTE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { ProjectService } from '@microsec/services';
import { BaseComponent } from '@lcms-components';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ENROLMENT_SETTINGS_CONSTANTS } from '../../../../settings/enrollment-settings/enrollment-settings.config';

@Component({
  selector: 'app-user-certificate-dashboard-form',
  templateUrl: './user-certificate-dashboard-form.component.html',
  styleUrls: ['./user-certificate-dashboard-form.component.scss'],
})
export class UserCertificateDashboardFormComponent extends BaseComponent implements OnInit {
  project: any = null;

  constructor(private projectSrv: ProjectService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.projectSrv.getProject(this.breadcrumbConfig?.projectId).subscribe({
      next: (project) => {
        this.project = project;
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }
  /**
   * Navigate to project settings
   */
  navigateToProjectSettings() {
    const currentUrl: string = this.router.routerState.snapshot.url;
    const urlArr: string[] = currentUrl.split('/').filter((p) => !!p);
    const subRouteArr = urlArr.filter((p, i) => [0, 1, 2, 3].includes(i));
    this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE));
    this.changeRoute(
      `${subRouteArr.join('/')}/${PROJECT_MANAGEMENT_CONSTANTS.ENROLMENT_SETTINGS.ROUTE}/${ENROLMENT_SETTINGS_CONSTANTS.USER_ENROLLMENT.ROUTE}`,
    );
  }

  /**
   * Get the user certificate enrollment link
   */
  get enrollmentLink(): string {
    return `${window.location.protocol}//${this.currentDomain}/${MODULE_CONSTANTS.USER_ENROLLMENT.ROUTE.replace(
      PROJECT_ID_PARAM_ROUTE,
      this.breadcrumbConfig?.projectId,
    )}`;
  }
}
