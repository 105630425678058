import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { KmsService, RepositoryService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE, SHORT_TEXT_MAX_LENGTH, VALIDATOR_TYPE } from '@microsec/constants';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { KEYRING_TYPE_VALUES, PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { KEY_MANAGEMENT_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  NAME: 'name',
  PAYLOAD: 'payload',
  DISTRIBUTION: 'distribution',
  VERSION: 'version',
  ARCHITECTURE: 'architecture',
  SIGNING: 'signing',
  KEYRING_ID: 'keyring_id',
  KEY_ID: 'key_id',
};

@Component({
  selector: 'app-package-form',
  templateUrl: './package-form.component.html',
  styleUrls: ['./package-form.component.scss'],
})
export class PackageFormComponent extends BaseComponent implements OnInit {
  repository: any = null;

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  acceptedFileTypesArr = ['.deb'];

  SHORT_TEXT_MAX_LENGTH = SHORT_TEXT_MAX_LENGTH;

  constructor(
    public dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private repositorySrv: RepositoryService,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.repository = this.dialogConfig.data?.repository;
    this.initForm();
    this.getKeyrings();
  }

  /**
   * Initialize form
   */
  initForm() {
    // Key ring dropdown and Refresh Button

    const keyringField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEYRING_ID,
      label: 'Keyring',
      field: 'dropdown',
      placeholder: 'Select a keyring',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New Keyring',
          styleClass: 'p-button-success',
          command: () => {
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE}` +
                `/${KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE}`,
              '_blank',
            );
          },
        },
      ] as any[],
      defaultValue: null,
      fieldInfo: 'Select Filesystem Type Keyring of the repository',
      hidden: true,
    } as FormItem);

    keyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });

    // GPG Key Field with Refresh Button

    const gpgKeyField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEY_ID,
      label: 'GPG Key',
      field: 'dropdown',
      placeholder: 'Select a GPG key',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New GPG Keys',
          styleClass: 'p-button-success',
          command: () => {
            const keyringId = this.form.getControlValue(FORM_PARAMS.KEYRING_ID) || null;
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE}` +
                `/${KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE}` +
                (!!keyringId ? `?keyring_id=${keyringId}` : '') +
                `&gpg_key_id=create`,
              '_blank',
            );
          },
        },
      ] as any[],
      defaultValue: null,
      fieldInfo: 'GPG Key of the repository',
      errorLabel: 'There is no Filesystem Keyring Selected',
      hidden: true,
    } as FormItem);

    gpgKeyField.refreshOptionsEvent?.subscribe(() => {
      const keyringId = this.form.getControlValue(FORM_PARAMS.KEYRING_ID) || null;
      !!keyringId ? this.getGPGKeys(keyringId) : null;
    });

    // Set Form Fields

    const fields = [
      Object.assign(new FormItem(), {
        label: 'Upload a package to make it available for devices.',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PAYLOAD,
        label: 'Sign Package',
        field: 'file',
        acceptedFileTypes: this.acceptedFileTypesArr,
        maxFileSizeInMB: 100,
        required: true,
        fieldInfo: 'Upload package',
        defaultValue: null,
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        label: 'Name',
        field: 'input',
        required: true,
        fieldInfo: 'Name of package',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.VERSION,
        label: 'Version',
        field: 'input',
        required: true,
        fieldInfo: 'Version of package',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DISTRIBUTION,
        label: 'Distribution',
        field: 'input',
        required: true,
        fieldInfo: 'Distribution of package',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ARCHITECTURE,
        label: 'Architecture',
        field: 'input',
        required: true,
        fieldInfo: 'Architecture of package',
        defaultValue: '',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.SIGNING,
        checkboxLabel: 'Sign package?',
        field: 'checkbox',
        fieldInfo: 'Sign the package',
        defaultValue: false,
      } as FormItem),
      keyringField,
      gpgKeyField,
    ];
    fields.forEach((field) => field.setMediumSize());
    this.fields = fields;
    this.initUploadFile();
    this.form?.setChangeEvent(FORM_PARAMS.SIGNING, (value: string) => {
      const keyringIdValue = this.form?.getControlValue(FORM_PARAMS.KEYRING_ID);
      if (!value) {
        this.form?.setControlValue(FORM_PARAMS.KEYRING_ID, null);
      }
      this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.KEYRING_ID, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form?.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_ID, !!value && !!keyringIdValue ? [VALIDATOR_TYPE.REQUIRED] : []);
    });
    this.form?.setChangeEvent(FORM_PARAMS.KEYRING_ID, (value: boolean) => {
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.KEY_ID, !!value ? [VALIDATOR_TYPE.REQUIRED] : []);
      this.form.setControlValue(FORM_PARAMS.KEY_ID, null);
      if (!!value) {
        this.getGPGKeys(value);
      }
    });
  }

  /**
   * Get keyrings
   */
  getKeyrings() {
    this.form.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const keyringField = this.fields.find((p) => p.name === FORM_PARAMS.KEYRING_ID);
          if (!!keyringField) {
            keyringField.options = (((rs?.data as any[]) || []).filter((keyring) => keyring.type === KEYRING_TYPE_VALUES.FILESYSTEM) as any[]).map(
              (key) => ({
                ...key,
                value: key.id,
                label: key.name,
              }),
            );
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get GPG keys
   * @param keyringId
   */
  getGPGKeys(keyringId: any) {
    this.form.isLoading = true;
    this.kmsSrv
      .getGPGKeys(keyringId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const gpgKeyField = this.fields.find((p) => p.name === FORM_PARAMS.KEY_ID);
          if (!!gpgKeyField) {
            gpgKeyField.options = ((rs?.data as any[]) || []).map((key) => ({
              ...key,
              value: key.id,
              label: key.label,
            }));
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Initialize upload file
   */
  initUploadFile() {
    const payloadField = this.fields.find((p) => p.name === FORM_PARAMS.PAYLOAD);
    if (!!payloadField) {
      payloadField.uploadEvent?.subscribe((event) => {
        if (!!event.target && !!event.target.files && !!event.target.files.length) {
          const file: any = (event.target.files as FileList).item(0);
          this.form.setControlValue(FORM_PARAMS.PAYLOAD, file);
          this.setPackageInfoFromFileName(file?.name);
        } else {
          this.form.setControlValue(FORM_PARAMS.PAYLOAD, null);
        }
        this.form.getControl(FORM_PARAMS.PAYLOAD)?.markAsTouched();
      });
    }
  }

  /**
   * Set package information from file name
   * @param fileName
   */
  setPackageInfoFromFileName(fileName: string) {
    if (!!fileName) {
      const fileNames = fileName.split('_');
      if (!!fileNames?.length) {
        const packageName = fileNames[0];
        const packageVersion = fileNames[1];
        const packageArchitecture = fileNames[2]?.split('.')[0];
        if (!!packageName) {
          this.form.setControlValue(FORM_PARAMS.NAME, packageName);
        }
        if (!!packageVersion) {
          this.form.setControlValue(FORM_PARAMS.VERSION, packageVersion);
        }
        if (!!packageArchitecture) {
          this.form.setControlValue(FORM_PARAMS.ARCHITECTURE, packageArchitecture);
        }
      }
    }
  }

  /**
   * Submit form
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    this.form.isLoading = true;
    const formValues = this.form.getRawValue();
    const formData = new FormData();
    formData.append(FORM_PARAMS.NAME, formValues[FORM_PARAMS.NAME]);
    formData.append(FORM_PARAMS.PAYLOAD, formValues[FORM_PARAMS.PAYLOAD]);
    formData.append(FORM_PARAMS.VERSION, formValues[FORM_PARAMS.VERSION]);
    formData.append(FORM_PARAMS.DISTRIBUTION, formValues[FORM_PARAMS.DISTRIBUTION]);
    formData.append(FORM_PARAMS.ARCHITECTURE, formValues[FORM_PARAMS.ARCHITECTURE]);
    formData.append(FORM_PARAMS.SIGNING, formValues[FORM_PARAMS.SIGNING]);
    if (!!formValues[FORM_PARAMS.SIGNING]) {
      formData.append(FORM_PARAMS.KEYRING_ID, formValues[FORM_PARAMS.KEYRING_ID]);
      formData.append(FORM_PARAMS.KEY_ID, formValues[FORM_PARAMS.KEY_ID]);
    }
    formData.append('project_id', this.breadcrumbConfig?.projectId);
    this.repositorySrv
      .signPackageByRepository(this.repository?.id, formData)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Uploaded package successfully');
          closeDialog();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }
}
