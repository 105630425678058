export enum CryptoAssetField {
  CRYPTOKEN_INFO = 'cryptoken_info',
  CRYPTO_KEYS = 'crypto_keys',
  CLIENT_CERTIFICATE = 'client_certificate',
  CA_CERTIFICATE = 'ca_certificate',
  CRYPTO_ALIAS = 'crypto_alias',
}

export const CRYPTO_ASSET_FIELD_VALUES = {
  CRYPTOKEN_INFO: { label: 'Cryptokens', value: CryptoAssetField.CRYPTOKEN_INFO },
  CRYPTO_KEYS: { label: 'Crypto Keys', value: CryptoAssetField.CRYPTO_KEYS },
  CLIENT_CERTIFICATE: { label: 'Client Certificates', value: CryptoAssetField.CLIENT_CERTIFICATE },
  CA_CERTIFICATE: { label: 'CA Certificates', value: CryptoAssetField.CA_CERTIFICATE },
  CRYPTO_ALIAS: { label: 'Crypto Aliases', value: CryptoAssetField.CRYPTO_ALIAS },
};

export const DESTINATION_TYPE_VALUES = {
  FILESYSTEM: 'filesystem',
  CRYPTOKEN: 'cryptoken',
};

export const CRYPTO_ASSET_CONFIG_VALUES: { [key in CryptoAssetField]: any } = {
  [CryptoAssetField.CRYPTOKEN_INFO]: {
    properties: [
      { label: 'Serial Number', value: 'serial_number', type: 'string' },
      { label: 'Label', value: 'label', type: 'string' },
      { label: 'Description', value: 'description', type: 'string' },
      { label: 'Manufacturer', value: 'manufacturer', type: 'string' },
      { label: 'HW Version', value: 'hw_version', type: 'string' },
      { label: 'FW Version', value: 'fw_version', type: 'string' },
      { label: 'PKCS#11 URI', value: 'uri', type: 'string' },
      { label: 'Module Path', value: 'module_path', type: 'string' },
      {
        label: 'Slots',
        value: 'slots',
        itemName: 'slot',
        extras: [
          { field: 'slot_id', header: 'ID', width: 8 },
          { field: 'status', header: 'Status', width: 10 },
          { field: 'description', header: 'Description', width: 12 },
          { field: 'uri', header: 'URI', width: 12 },
        ],
        cols: [],
        type: 'array',
      },
    ],
    responseKey: 'tokens',
    label: 'Cryptokens',
    icon: 'fa fa-microchip',
    deletable: false,
    creatable: false,
  },
  [CryptoAssetField.CRYPTO_KEYS]: {
    properties: [
      { label: 'Label', value: 'label', type: 'string' },
      { label: 'Type', value: 'type', type: 'string' },
      { label: 'Algorithm', value: 'algorithm', type: 'string' },
      { label: 'Length', value: 'size', type: 'string' },
      { label: 'Used by', value: 'used_by', type: 'string' },
      { label: 'File Path', value: 'file_path', type: 'string' },
      { label: 'File Permission', value: 'file_permission', type: 'string' },
      { label: 'Curve', value: 'curve', type: 'string' },
      { label: 'PKCS#11 URI', value: 'pkcs11_uri', type: 'string' },
      { label: 'Created Date', value: 'created', type: 'date' },
      { label: 'Updated Date', value: 'updated', type: 'date' },
    ],
    responseKey: 'keys',
    label: 'Crypto Keys',
    buttonLabel: 'Create/Import Key',
    icon: 'fa fa-key',
    deletable: true,
    creatable: true,
    importable: true, // Import Filesystem Key to Cryptoken feature
  },
  [CryptoAssetField.CLIENT_CERTIFICATE]: {
    properties: [
      { label: 'Subject', value: 'subject', type: 'string' },
      { label: 'Issued By', value: 'issued_by', type: 'string' },
      { label: 'Type', value: 'type', type: 'string' },
      { label: 'Used By', value: 'used_by', type: 'arraylist' },
      { label: 'File Path', value: 'file_path', type: 'string' },
      { label: 'File Permission', value: 'file_permission', type: 'string' },
      { label: 'PKCS#11 URI', value: 'pkcs11_uri', type: 'string' },
      { label: 'Issue Date', value: 'issued_on', type: 'date' },
      { label: 'Expiry Date', value: 'expires_on', type: 'date' },
    ],
    responseKey: 'certificates',
    label: 'Client Certificates',
    buttonLabel: 'Create/Import Client Certificate',
    icon: 'fa fa-file-lines',
    deletable: false,
    creatable: false,
  },
  [CryptoAssetField.CA_CERTIFICATE]: {
    properties: [
      { label: 'Subject', value: 'subject', type: 'string' },
      { label: 'Issued By', value: 'issued_by', type: 'string' },
      { label: 'Type', value: 'type', type: 'string' },
      { label: 'Used By', value: 'used_by', type: 'arraylist' },
      { label: 'File Path', value: 'file_path', type: 'string' },
      { label: 'File Permission', value: 'file_permission', type: 'string' },
      { label: 'Issue Date', value: 'issued_on', type: 'date' },
      { label: 'Expiry Date', value: 'expires_on', type: 'date' },
    ],
    responseKey: 'certificates',
    label: 'CA Certificates',
    buttonLabel: 'Import CA Certificate',
    icon: 'fa fa-file-lines',
    deletable: true,
    creatable: true,
  },
  [CryptoAssetField.CRYPTO_ALIAS]: {
    properties: [
      { label: 'ID', value: 'id', type: 'string' },
      { label: 'File System Key Path', value: 'file_path', type: 'string' },
      { label: 'Cryptoken', value: 'pkcs11_uri', type: 'string' },
    ],
    responseKey: 'crypto_aliases',
    label: 'Crypto Alias',
    buttonLabel: 'Create Crypto Alias',
    icon: 'fa fa-microchip',
    deletable: true,
    creatable: true,
  },
};

// ----------------- CREATE/IMPORT FORM -----------------
export const SELECT_OPTIONS_VALUES = {
  CREATE: 'create',
  IMPORT: 'import',
};

export const FORM_SELECT_OPTIONS = [
  {
    label: 'Create',
    value: SELECT_OPTIONS_VALUES.CREATE,
  },
  {
    label: 'Import',
    value: SELECT_OPTIONS_VALUES.IMPORT,
  },
];

// ---------- CLIENT KEY FORM ---------------
export const KEY_TYPE_VALUES = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  SYMMETRIC: 'symmetric',
  ASYMMETRIC: 'asymmetric',
};

export const KEY_TYPE_OPTIONS = [
  { label: 'Asymmetric', value: KEY_TYPE_VALUES.ASYMMETRIC },
  // { label: 'Symmetric', value: KEY_TYPE_VALUES.SYMMETRIC },
  // { label: 'Public', value: KEY_TYPE_VALUES.PUBLIC },
  // { label: 'Private', value: KEY_TYPE_VALUES.PRIVATE },
];

export const ALL_KEY_TYPE_OPTIONS = [
  { label: 'Asymmetric', value: KEY_TYPE_VALUES.ASYMMETRIC },
  { label: 'Symmetric', value: KEY_TYPE_VALUES.SYMMETRIC },
  { label: 'Public', value: KEY_TYPE_VALUES.PUBLIC },
  { label: 'Private', value: KEY_TYPE_VALUES.PRIVATE },
];

export const KEY_SOURCE_TYPE_VALUES = {
  SERVER: 'server',
  DEVICE: 'device',
  // UNKNOWN: 'unknown',
};

export const KEY_SOURCE_OPTIONS = [
  { label: 'Device', value: KEY_SOURCE_TYPE_VALUES.DEVICE },
  { label: 'Server', value: KEY_SOURCE_TYPE_VALUES.SERVER },
  // { label: 'Unknown', value: KEY_SOURCE_TYPE_VALUES.UNKNOWN },
];

export const KEY_ALGORITHM_VALUES = {
  ECC: 'ecc',
  RSA: 'rsa',
  AES: 'aes',
  DSA: 'dsa',
  ECDSA: 'ecdsa',
  DES: 'des',
  RC: 'rc',
};

export const KEY_ALGORITHM_OPTIONS = [
  { label: 'RSA', value: KEY_ALGORITHM_VALUES.RSA },
  { label: 'ECC', value: KEY_ALGORITHM_VALUES.ECC },
];

export const ALL_KEY_ALGORITHM_OPTIONS = [
  { label: 'RSA', value: KEY_ALGORITHM_VALUES.RSA },
  { label: 'ECC', value: KEY_ALGORITHM_VALUES.ECC },
  { label: 'DSA', value: KEY_ALGORITHM_VALUES.DSA },
  { label: 'AES', value: KEY_ALGORITHM_VALUES.AES },
  { label: 'DES', value: KEY_ALGORITHM_VALUES.DES },
  { label: 'ECDSA', value: KEY_ALGORITHM_VALUES.ECDSA },
  { label: 'RC', value: KEY_ALGORITHM_VALUES.RC },
];

export const KEY_CURVE_TYPE_VALUES = {
  PRIME256: 'prime256v1',
  SECP384: 'secp384r1',
  SECP521: 'secp521r1',
};

export const KEY_CURVE_OPTIONS = [
  { label: 'prime256v1', value: KEY_CURVE_TYPE_VALUES.PRIME256 },
  { label: 'secp384r1', value: KEY_CURVE_TYPE_VALUES.SECP384 },
  { label: 'secp521r1', value: KEY_CURVE_TYPE_VALUES.SECP521 },
];

export const RSA_SIZE_OPTIONS = [
  { value: 2048, label: '2048' },
  { value: 3072, label: '3072' },
  { value: 4096, label: '4096' },
];
