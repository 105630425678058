import { Component, OnDestroy, OnInit } from '@angular/core';
import { deviceSummarySelectors, fromDeviceSummaryActions } from '@ngrx-device-summary';
import { BaseComponent } from '@lcms-components';
import { BANNER_CHECKBOX_TYPE_VALUES, HIDE_OVERVIEW_BANNER_KEY } from './overview.constants';
import { CaManagementService, DeviceService } from '@lcms-services';
import { finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  isLoading = false;

  summaryLastRefresh$: any = this.store.select(deviceSummarySelectors.lastRefresh);

  selectedIndex = 0;

  BANNER_CHECKBOX_TYPE_VALUES = BANNER_CHECKBOX_TYPE_VALUES;

  HIDE_OVERVIEW_BANNER_KEY = HIDE_OVERVIEW_BANNER_KEY;

  bannerCheckboxContent: any[] = [
    { id: BANNER_CHECKBOX_TYPE_VALUES.CA, label: 'Create an in-built CA (Certificate Authority) or connect to an external CA', checked: false },
    {
      id: BANNER_CHECKBOX_TYPE_VALUES.DEVICE,
      label: 'Enroll devices with our MicroAgent™ or manually using the dashboard, EST, SCEP, or REST API through certificate generation',
      checked: true,
    },
    {
      id: BANNER_CHECKBOX_TYPE_VALUES.MICROAGENT,
      label: 'Manage your MicroAgent™ devices by monitoring telemetry, setting device policies or managing cryptographic assets',
      checked: false,
    },
  ];

  showBanner: boolean = false;

  constructor(
    private caManagementSrv: CaManagementService,
    private deviceSrv: DeviceService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.getCasAndDevices();
    this.store.dispatch(new fromDeviceSummaryActions.GetDeviceSummary(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId));
    this.subscriptions.push(
      this.store.select(deviceSummarySelectors.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );
  }

  getCasAndDevices() {
    this.isLoading = true;
    forkJoin([
      this.caManagementSrv.getCAManagers(this.breadcrumbConfig?.projectId),
      this.deviceSrv.getAllDevicesNonPaginated(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId),
    ])
      .pipe(
        finalize(() => {
          // check to show overview banner or not
          if (!this.checkBannerStorage() || this.bannerCheckboxContent.every((c: any) => !!c.checked)) {
            this.showBanner = false;
          } else {
            this.showBanner = true;
          }
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this.bannerCheckboxContent = this.bannerCheckboxContent.map((c) => {
            switch (c.id) {
              case BANNER_CHECKBOX_TYPE_VALUES.CA: {
                return { ...c, checked: !!rs[0]?.data?.length };
              }
              case BANNER_CHECKBOX_TYPE_VALUES.DEVICE: {
                return { ...c, checked: !!rs[1].devices?.length };
              }
              case BANNER_CHECKBOX_TYPE_VALUES.MICROAGENT: {
                return { ...c, checked: !!rs[1].devices?.some((d: any) => !!d.uses_agent) };
              }
            }
          });
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  closeBanner() {
    this.showBanner = false;
    this.storeBannerVisibility(true);
  }

  checkBannerStorage(): boolean {
    const storedValue = localStorage.getItem(this.bannerOverviewStorageKey);
    return storedValue ? !!JSON.parse(storedValue) : true;
  }

  storeBannerVisibility(value: boolean): void {
    localStorage.setItem(this.bannerOverviewStorageKey, JSON.stringify(value));
  }

  private get bannerOverviewStorageKey() {
    return `${HIDE_OVERVIEW_BANNER_KEY}_${this.breadcrumbConfig?.organizationId}_${this.breadcrumbConfig?.projectId}`;
  }

  override ngOnDestroy(): void {
    this.cleanup();
    this.store.dispatch(new fromDeviceSummaryActions.CancelGetSummaryRequest());
  }

  /**
   * Refresh device summary
   */
  refresh() {
    this.store.dispatch(new fromDeviceSummaryActions.GetDeviceSummary(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId));
  }
}
