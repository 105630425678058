import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { SharedProjectFormComponent } from '../../../shared-components/shared-project-form/shared-project-form.component';
import { UsersComponent } from '@lcms-products';

export const PROJECT_SETTINGS_CONSTANTS = {
  PROJECT_INFORMATION: {
    ROUTE: 'project-information',
    LABEL: 'Project Information',
  },
  DEVICE_VULNERABILITY_MANAGEMENT: {
    ROUTE: 'device-vulnerability-management',
    LABEL: 'Device Vulnerability Management',
  },
  LABELS: {
    ROUTE: 'project-labels',
    LABEL: 'Project Labels',
  },
  CRYPTOKEN_MANAGER: {
    ROUTE: 'cryptoken-manager',
    LABEL: 'Cryptoken Manager',
  },
  KMS: {
    ROUTE: 'keyring',
    LABEL: 'Keyrings',
  },
  PROJECT_USERS: {
    ROUTE: 'project-users',
    LABEL: 'Project Users',
  },
};

export const PROJECT_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.PROJECT_INFORMATION.LABEL,
    component: SharedProjectFormComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: PROJECT_SETTINGS_CONSTANTS.PROJECT_USERS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.PROJECT_USERS.LABEL,
    component: UsersComponent,
  },
];
