// LAMBDA_TYPES
export const LAMBDA_TYPES = {
  INPUT: 'input',
  PROCESSING: 'processing',
  VERIFICATION: 'verification',
};

// OUTPUT MIME TYPES
export const OUTPUT_MIME_TYPES = [
  { value: 'application/octet-stream', label: 'application/octet-stream' },
  { value: 'application/json', label: 'application/json' },
];

// Initial Lambda
export const INITIAL_INPUT_VALIDATION_LAMBDA = `# EXAMPLE INPUT VALIDATION LAMBDA
# checks whether the input payload is longer than 5 characters (in case of input being a string)
# modify based on your needs
try:
  if len(temp_dict['input']) < 5:
    temp_dict['rc'] = 1
  else:
    temp_dict['rc'] = 0
except Exception as e:
  print(e)
  temp_dict['rc'] = 1`;
export const INITIAL_PROCESSING_LAMBDA = `# EXAMPLE PROCESSING LAMBDA
# signs the payload and returns signature
# modify based on your needs
sign = temp_dict['sign']
try:
  temp_dict['input'] = str(temp_dict['input'])
  sig = sign(temp_dict['input'], temp_dict['ca_id'], temp_dict['ca_server'], temp_dict['hash_algo'])
  if not sig:
    raise
  temp_dict['output'] = json.dumps(
    {"signature": sig, "input": temp_dict['input']}
  )
  temp_dict['rc'] = 0
except Exception as e:
  print(e)
  temp_dict['rc'] = 1`;
export const INITIAL_VERIFICATION_LAMBDA = `# EXAMPLE VERIFICATION LAMBDA
# verifies the payload & signature
# modify based on your needs
verify_signature = temp_dict['verify_signature']
try:
  envelope = json.loads(temp_dict['envelope'])
  verify_signature(
    temp_dict['ca_id'],
    temp_dict['ca_server'],
    envelope['input'],
    envelope['signature'],
    temp_dict['envelope_template_id']
  )
  temp_dict['rc'] = 0
except Exception as e:
  print(e)
  temp_dict['rc'] = 1`;

export const INITIAL_LAMBDAS: any = {
  INPUT_VALIDATION_LAMBDA: INITIAL_INPUT_VALIDATION_LAMBDA,
  PROCESSING_LAMBDA: INITIAL_PROCESSING_LAMBDA,
  VERIFICATION_LAMBDA: INITIAL_VERIFICATION_LAMBDA,
};
