import { AfterViewInit, Component } from '@angular/core';
import { DEVICE_MANAGEMENT_FEATURES, PKI_MANAGEMENT_FEATURES, QUOTAS_FEATURES } from '@lcms-constants';
import { BaseComponent } from '@lcms-components';
import { ENROLLMENT_CONFIGS } from './enrollment.config';
import { DeviceService } from '@lcms-services';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss'],
})
export class EnrollmentComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  enrollmentOptions: any[] = [];

  totalDeviceNumber = 0;

  constructor(private deviceSrv: DeviceService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.getAllDevices();
  }

  /**
   * Get all devices
   */
  getAllDevices() {
    this.isLoading = true;
    this.deviceSrv
      .getAllDevicesNonPaginated(this.breadcrumbConfig?.organizationId, this.breadcrumbConfig?.projectId, null, '')
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          this.totalDeviceNumber = rs?.devices.length || 0;
          this.getConfigs();
        },
        error: (error: any) => {
          this.showErrorMessage(error);
        },
      });
  }

  /**
   * Get configs
   */
  getConfigs() {
    let configs = [];
    if (this.checkDeviceQuota > this.totalDeviceNumber) {
      configs = this.util.cloneObjectArray(ENROLLMENT_CONFIGS);
      if (!this.isLcmsAgentFeatured) {
        configs = configs.filter((p) => p.key !== 'agent');
      }
      if (!this.isVirtualDeviceFeatured) {
        configs = configs.filter((p) => p.key !== 'virtual');
      }
      if (!this.isX509Featured || !this.isProtocolGenericFeatured || !this.isKmsFeatured) {
        configs = configs.filter((p) => p.key !== 'csr');
      }
      this.enrollmentOptions = configs;
    }
  }

  /**
   * Check if X509 featured
   */
  get checkDeviceQuota() {
    return this.getQuotaLimitation(QUOTAS_FEATURES.DEVICES);
  }

  /**
   * Check if X509 featured
   */
  get isX509Featured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509);
  }

  /**
   * Check if virtual device featured
   */
  get isVirtualDeviceFeatured() {
    return false; //this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.VIRTUAL_DEVICES);
  }

  /**
   * Check if LCMS agent featured
   */
  get isLcmsAgentFeatured() {
    return this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.LCMS_AGENT);
  }

  /**
   * Check if protocol generic featured
   */
  get isProtocolGenericFeatured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.PROTOCOL_GENERIC);
  }

  /**
   * Check if KMS featured
   */
  get isKmsFeatured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.KMS);
  }

  /**
   * Get the device enrollment length
   */
  get enrollmentOptionLength() {
    return this.enrollmentOptions.length || 0;
  }
}
