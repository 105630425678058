import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { DeviceService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, finalize } from 'rxjs';
import { DESTINATION_TYPE_VALUES } from '../device-crypto-assets.config';

const FORM_PARAMS = {
  CRYPTOKEN_KEY_ID: 'cryptoken_key_id',
  CRYPTOKEN_ID: 'cryptoken_id',
  FILESYSTEM_KEY_ID: 'fs_key_id',
  NAME: 'name',
};

@Component({
  selector: 'app-dca-crypto-alias-form',
  templateUrl: './dca-crypto-alias-form.component.html',
  styleUrls: ['./dca-crypto-alias-form.component.scss'],
})
export class DcaCryptoAliasFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  device: any = null;

  cryptokens: any[] = [];

  cryptokenKeys: any[] = [];

  filesystemKeys: any[] = [];

  getCryptokens: () => Observable<any[]>;

  getCryptoKeys: () => Observable<any[]>;

  CREATE_LABEL = CREATE_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private deviceSrv: DeviceService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.device = this.dialogConfig.data?.device;
    this.cryptokens = this.dialogConfig.data?.tokens;
    this.cryptokenKeys = ((this.dialogConfig.data?.keys as any[]) || []).filter((key: any) => key.destination === DESTINATION_TYPE_VALUES.CRYPTOKEN);
    this.filesystemKeys = ((this.dialogConfig.data?.keys as any[]) || []).filter(
      (key: any) => key.destination === DESTINATION_TYPE_VALUES.FILESYSTEM,
    );
    this.getCryptokens = this.dialogConfig.data?.getCryptokens;
    this.getCryptoKeys = this.dialogConfig.data?.getCryptoKeys;
    this.initForm();
  }

  fetchCryptokens() {
    this.form.isLoading = true;
    this.getCryptokens()
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe((tokens: any[]) => {
        this.cryptokens = [...tokens] || [];
        const cryptokenField = this.fields.find((field) => field.name === FORM_PARAMS.CRYPTOKEN_ID);
        if (cryptokenField) {
          cryptokenField.options = tokens.map((token) => ({ label: token.label, value: token.id }));
          if (!cryptokenField.options.find((option) => option.value === this.form?.getControlValue(FORM_PARAMS.CRYPTOKEN_ID))) {
            this.form.setControlValue(FORM_PARAMS.CRYPTOKEN_ID, null);
          }
        }
      });
  }

  fetchCryptoKeys(destination: string) {
    this.form.isLoading = true;
    this.getCryptoKeys()
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe((keys: any[]) => {
        switch (destination) {
          case DESTINATION_TYPE_VALUES.CRYPTOKEN: {
            this.cryptokenKeys = [...keys].filter((key) => key.destination === DESTINATION_TYPE_VALUES.CRYPTOKEN);
            const cryptoKeyField = this.fields.find((field) => field.name === FORM_PARAMS.CRYPTOKEN_KEY_ID);
            if (cryptoKeyField) {
              cryptoKeyField.options = this.cryptokenKeys.map((key) => ({ label: key.label, value: key.id }));
              if (!cryptoKeyField.options.find((option) => option.value === this.form?.getControlValue(FORM_PARAMS.CRYPTOKEN_KEY_ID))) {
                this.form.setControlValue(FORM_PARAMS.CRYPTOKEN_KEY_ID, null);
              }
            }
            break;
          }
          case DESTINATION_TYPE_VALUES.FILESYSTEM: {
            this.filesystemKeys = [...keys].filter((key) => key.destination === DESTINATION_TYPE_VALUES.FILESYSTEM);
            const FsKeyField = this.fields.find((field) => field.name === FORM_PARAMS.FILESYSTEM_KEY_ID);
            if (FsKeyField) {
              FsKeyField.options = this.filesystemKeys.map((key) => ({ label: key.label, value: key.id }));
              if (!FsKeyField.options.find((option) => option.value === this.form?.getControlValue(FORM_PARAMS.FILESYSTEM_KEY_ID))) {
                this.form.setControlValue(FORM_PARAMS.FILESYSTEM_KEY_ID, null);
              }
            }
            break;
          }
        }
      });
  }

  /**
   * Init form
   */
  initForm() {
    const cryptokenField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.CRYPTOKEN_ID,
      label: 'Cryptoken',
      field: 'dropdown',
      options: this.cryptokens.map((token) => ({ label: token.label, value: token.id })),
      refreshOptionsEvent: new EventEmitter<any>(),
      placeholder: 'Select a cryptoken',
      fieldInfo: 'Select a cryptoken which will be used for aliasing',
      defaultValue: this.cryptokens[0].id || null,
      required: true,
      hidden: false,
    } as FormItem);
    const cryptoKeyField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.CRYPTOKEN_KEY_ID,
      label: 'Cryptoken Key',
      field: 'dropdown',
      options: this.cryptokenKeys
        .filter((key) => key.destination === DESTINATION_TYPE_VALUES.CRYPTOKEN)
        .map((key) => ({ label: key.label, value: key.id })),
      refreshOptionsEvent: new EventEmitter<any>(),
      placeholder: 'Select a crypto key',
      fieldInfo: 'Select a cryptoken based private key which will be used for aliasing the file system key to',
      defaultValue: this.cryptokenKeys[0].id || null,
      required: true,
      hidden: false,
    } as FormItem);
    const FsKeyField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.FILESYSTEM_KEY_ID,
      label: 'Filesystem Key',
      field: 'dropdown',
      options: this.filesystemKeys
        .filter((key) => key.destination === DESTINATION_TYPE_VALUES.FILESYSTEM)
        .map((key) => ({ label: key.label, value: key.id })),
      refreshOptionsEvent: new EventEmitter<any>(),
      placeholder: 'Select a filesystem key',
      fieldInfo: 'Select a filesystem key which will be used for aliasing',
      defaultValue: this.filesystemKeys[0].id || null,
      required: true,
      hidden: false,
    } as FormItem);

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        field: 'text',
        labelStyleClass: 'font-bold',
        label:
          'Create a new Crypto Alias which will allow legacy applications to leverage cryptoken based keys instead of insecure file system keys.',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NAME,
        field: 'input',
        label: 'Name',
        required: true,
        fieldInfo: 'Crypto Alias Name',
        placeholder: 'Enter crypto alias name',
      } as FormItem),
      FsKeyField,
      cryptokenField,
      cryptoKeyField,
      Object.assign(new FormItem(), {
        field: 'text',
        label:
          '*WARNING: Creating a Crypto Alias will replace the selected file system key content. It is recommended to backup the key contents beforehand, to be able to restore the contents, if needed.',
      } as FormItem),
    ];

    fields.forEach((field) => field.setMediumSize());
    cryptokenField.refreshOptionsEvent?.subscribe(() => {
      this.fetchCryptokens();
    });
    cryptoKeyField.refreshOptionsEvent?.subscribe(() => {
      this.fetchCryptoKeys(DESTINATION_TYPE_VALUES.CRYPTOKEN);
    });
    FsKeyField.refreshOptionsEvent?.subscribe(() => {
      this.fetchCryptoKeys(DESTINATION_TYPE_VALUES.FILESYSTEM);
    });
    this.fields = fields;
  }

  /**
   * Create Crypto Alias
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.util.cloneDeepObject(this.form.getRawValue());
    this.form.isLoading = true;
    this.deviceSrv
      .createCryptoAlias(this.device.id, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Crypto Alias creation is being processed. Check events tab for more details.`);
          closeDialog();
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }
}
