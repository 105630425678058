import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MODULE_CONSTANTS, USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@lcms-components';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss'],
})
export class ProjectManagementComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(private injector: Injector) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.store.dispatch(new fromLayoutActions.SetLeftNavbar(true));
    const PROJECT_MANAGEMENT_CHILDREN = this.injector.get('PROJECT_MANAGEMENT_CHILDREN');
    this.store.dispatch(new fromLayoutActions.SetModule(MODULE_CONSTANTS.PROJECT_MANAGEMENT.ROUTE));
    const checkedItems: any[] = [];
    // Read only
    const readonlyItems = this.util
      .cloneObjectArray(PROJECT_MANAGEMENT_CHILDREN)
      .filter(
        (p) =>
          p.route === PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE ||
          p.route === PROJECT_MANAGEMENT_CONSTANTS.CERTIFICATES.ROUTE ||
          p.route === PROJECT_MANAGEMENT_CONSTANTS.REPOSITORIES.ROUTE ||
          p.route === PROJECT_MANAGEMENT_CONSTANTS.CHAINS_OF_TRUST.ROUTE ||
          p.route === PROJECT_MANAGEMENT_CONSTANTS.WORKFLOWS.ROUTE,
      );
    checkedItems.push(...readonlyItems.map((item) => ({ ...item, visible: this.checkPermissionsByScope(USER_ROLE.READ_ONLY) })));
    // Admin
    const adminItems = this.util
      .cloneObjectArray(PROJECT_MANAGEMENT_CHILDREN)
      .filter(
        (p) =>
          p.route === PROJECT_MANAGEMENT_CONSTANTS.USER_CERTIFICATES.ROUTE ||
          p.route === PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE ||
          p.route === PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE ||
          p.route === PROJECT_MANAGEMENT_CONSTANTS.ENROLMENT_SETTINGS.ROUTE,
      );
    checkedItems.push(...adminItems.map((item) => ({ ...item, visible: this.checkPermissionsByScope(USER_ROLE.ADMIN) })));

    this.store.dispatch(new fromLayoutActions.UpdateReplacedItems(checkedItems));
  }
}
