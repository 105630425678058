<div class="flex justify-content-center login-container">
  <div class="flex flex-column align-self-center wrapper">
    @if (!!messageValue.length) {
      <p-messages [(value)]="messageValue" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [enableService]="false" />
    }
    <microsec-card>
      <div class="login-form">
        <microsec-form-builder
          [isPage]="true"
          #fb
          [formPadding]="'4rem'"
          [hasInfoFields]="false"
          [fields]="fields"
          [shouldShowActionButtons]="false"
          (submitEvent)="login()" />
      </div>
    </microsec-card>
  </div>
</div>

<ng-template #forgotPasswordTemplate>
  <div class="pt-2">
    <label class="link pt-3" (click)="showForgotPasswordInfo()">Forgot Password?</label>
  </div>
</ng-template>

<ng-template #loginActionsTemplate>
  @if (!!form && !!form.form) {
    <div class="flex justify-content-between login-actions" [formGroup]="form.form">
      @if (!!isLDAPSettingEnabled && !!isLDAPFeatured) {
        <!-- LDAP Login -->
        <p-checkbox [binary]="true" label="LDAP Login" [formControlName]="FORM_PARAMS.IS_LDAP" />
      } @else {
        <div>
          <!-- Empty -->
        </div>
      }
      <p-button severity="primary" type="submit" label="Login" [loading]="isLoginLoading" [disabled]="!form || !form.form || !form.form.valid" />
    </div>
  }
</ng-template>
