import { faAddressBook, faBuilding, faFileContract, faFileLines, faFileWaveform, faUserCog, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Config2faComponent } from './access/config2fa/config2fa.component';
import { LdapComponent } from './access/ldap/ldap.component';
import { UsersComponent } from './access/users/users.component';
import { LicensesComponent } from './licenses/licenses.component';
import { EventLogsComponent } from './notification/event-logs/event-logs.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { FEATURES, PKI_MANAGEMENT_FEATURES, SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { GLOBAL_SETTINGS_CONSTANTS, GLOBAL_SETTINGS_GROUPS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { CONFIG_2FA_CHILDREN } from './access/config2fa/config2fa.config';
import { LICENSES_CHILDREN } from './licenses/licenses.config';
import { SystemLogsComponent } from './notification/system-logs/system-logs.component';

export const GLOBAL_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    group: GLOBAL_SETTINGS_GROUPS.ORGANIZATION,
    route: GLOBAL_SETTINGS_CONSTANTS.ORGANIZATIONS.ROUTE,
    icon: faBuilding,
    label: GLOBAL_SETTINGS_CONSTANTS.ORGANIZATIONS.LABEL,
    component: OrganizationsComponent,
  },
  {
    group: GLOBAL_SETTINGS_GROUPS.ACCESS_SETTINGS,
    route: GLOBAL_SETTINGS_CONSTANTS.USERS.ROUTE,
    icon: faUsers,
    label: GLOBAL_SETTINGS_CONSTANTS.USERS.LABEL,
    component: UsersComponent,
  },
  {
    group: GLOBAL_SETTINGS_GROUPS.ACCESS_SETTINGS,
    route: GLOBAL_SETTINGS_CONSTANTS.LDAP.ROUTE,
    icon: faAddressBook,
    label: GLOBAL_SETTINGS_CONSTANTS.LDAP.LABEL,
    component: LdapComponent,
    featureName: FEATURES.SYSTEM_MANAGEMENT,
    featureCheckField: SYSTEM_MANAGEMENT_FEATURES.AUTH_LDAP,
  },
  {
    group: GLOBAL_SETTINGS_GROUPS.ACCESS_SETTINGS,
    route: GLOBAL_SETTINGS_CONSTANTS.CONFIG_2FA.ROUTE,
    icon: faUserCog,
    label: GLOBAL_SETTINGS_CONSTANTS.CONFIG_2FA.LABEL,
    component: Config2faComponent,
    children: CONFIG_2FA_CHILDREN,
  },
  {
    group: GLOBAL_SETTINGS_GROUPS.NOTIFICATION_SETTINGS,
    route: GLOBAL_SETTINGS_CONSTANTS.EVENT_LOGS.ROUTE,
    icon: faFileLines,
    label: GLOBAL_SETTINGS_CONSTANTS.EVENT_LOGS.LABEL,
    component: EventLogsComponent,
  },
  {
    group: GLOBAL_SETTINGS_GROUPS.NOTIFICATION_SETTINGS,
    route: GLOBAL_SETTINGS_CONSTANTS.SYSTEM_LOGS.ROUTE,
    icon: faFileWaveform,
    label: GLOBAL_SETTINGS_CONSTANTS.SYSTEM_LOGS.LABEL,
    component: SystemLogsComponent,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.X509,
  },
  {
    group: GLOBAL_SETTINGS_GROUPS.LICENSE,
    route: GLOBAL_SETTINGS_CONSTANTS.LICENSES.ROUTE,
    icon: faFileContract,
    label: GLOBAL_SETTINGS_CONSTANTS.LICENSES.LABEL,
    component: LicensesComponent,
    children: LICENSES_CHILDREN,
  },
];
