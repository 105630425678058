<microsec-loading-overlay [isLoading]="isLoading">
  @if (!!enrollmentOptionLength) {
    <microsec-details-menu [items]="enrollmentOptions" />
  } @else {
    <label class="text-white text-lg">
      {{
        'Number of enrolled devices have reached the maximum quota of ' +
          checkDeviceQuota +
          ' devices. Please upgrade to higher tier or remove existing devices to enroll addtional devices.'
      }}
    </label>
  }
</microsec-loading-overlay>
