import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import { SAVE_CHANGES_LABEL } from '@microsec/constants';
import { finalize } from 'rxjs/operators';

const FORM_PARAMS = {
  CURRENT_PASSWORD: 'current_password',
  NEW_PASSWORD: 'password',
  CONFIRM_PASSWORD: 'password_confirmation',
};

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent extends BaseComponent implements OnInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  ngOnInit() {
    this.initForm();
  }

  /**
   * Init the initial data for the form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: 'Change your password',
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CURRENT_PASSWORD,
        label: 'Current Password',
        field: 'password',
        feedback: false,
        required: true,
        fieldInfo: 'Type your current password',
        focused: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NEW_PASSWORD,
        label: 'New Password',
        field: 'password',
        required: true,
        fieldInfo: 'Type a new password',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CONFIRM_PASSWORD,
        label: 'Confirm Password',
        field: 'password',
        required: true,
        fieldInfo: 'Retype the new password',
      } as FormItem),
    ];
    fields.forEach((fieldItem) => fieldItem.setFullSize());
    this.fields = fields;
  }

  /**
   * Submit the changed password payload
   * @returns
   */
  onSubmit() {
    const payload = { ...this.form.getRawValue() };
    if (payload[FORM_PARAMS.NEW_PASSWORD] !== payload[FORM_PARAMS.CONFIRM_PASSWORD]) {
      this.showErrorMessage('Password and password confirmation do not match');
      this.form.isLoading = false;
      return;
    }
    this.authSrv
      .changePassword(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Your password has been changed successfully');
          this.initForm();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Show the message to the current user if forgot password
   */
  showForgotPasswordInfo() {
    this.showInfoMessage('Please get in contact with your system administrator or email support@usec.io to reset your password.');
  }
}
