import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DELETE_LABEL, USER_ROLE } from '@microsec/constants';
import { DEVICE_MANAGEMENT_FEATURES } from '@lcms-constants';
import { PackageService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { Table, TableService } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { PackageFormComponent } from './package-form/package-form.component';
import { CommonTableComponent } from '@microsec/components';
import { BehaviorSubject } from 'rxjs';
import { ActionMenuItem, CommonToolbarConfiguration, CommonToolbarResult } from '@microsec/models';

const FIELDS = {
  NAME: 'Name',
  DEVICES_INSTALLED: 'Devices Installed',
  DEVICES_WITH_OUTDATED_VERSION: 'Devices with outdated version',
};

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
  providers: [Table, TableService],
})
export class PackagesComponent extends BaseComponent implements OnInit {
  isLoading = false;

  @ViewChild('dt') dt!: CommonTableComponent;

  cols = [
    { field: 'name', header: FIELDS.NAME, width: 10 },
    { field: 'installed_devices', header: FIELDS.DEVICES_INSTALLED, width: 10 },
    {
      field: 'out_of_date_devices',
      header: FIELDS.DEVICES_WITH_OUTDATED_VERSION,
      width: 12,
    },
  ];

  selectedPackage: any = null;

  selectedPackages: any[] = [];

  allPackages: any[] = [];

  values: any[] = [];

  filterObject$ = new BehaviorSubject<CommonToolbarResult | null>(null);

  filterObjectObs = this.filterObject$.asObservable();

  selectedCols: any[] = [];

  _selectedColFields: string[] = [];

  get selectedColFields(): string[] {
    return this._selectedColFields;
  }

  set selectedColFields(value: string[]) {
    this._selectedColFields = value;
    this.selectedCols = (this.cols || []).filter((col) => value?.includes(col.field));
  }

  filterConfiguration: CommonToolbarConfiguration = {
    types: ['search', 'filter'],
    searchPlaceholder: 'Search...',
    filters: {
      0: {
        key: 'status',
        label: 'Status',
        type: 'dropdown',
        options: [
          { value: true, label: 'Packages with Vulnerabilities' },
          { value: false, label: 'Packages without Vulnerabilities' },
        ],
      },
    },
  };

  filterSearch = '';

  actionsMenuItems: ActionMenuItem[] = [];

  DELETE_LABEL = DELETE_LABEL;

  constructor(
    public el: ElementRef,
    private packageSrv: PackageService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkDeviceManagementFeatureEnabled(DEVICE_MANAGEMENT_FEATURES.PACKAGES)) {
      if (!!this.checkPermissionsByScope(USER_ROLE.READ_ONLY, true)) {
        this.selectedColFields = (this.cols || []).map((col) => col.field);
        this.actionsMenuItems = [
          {
            label: 'Update',
            icon: 'icon_upgrade',
            isSvg: true,
            disabled: ({ rowData }) => rowData['out_of_date_devices'] === 0,
            command: ({ rowData }: any) => this.openPackageForm('Update', [rowData]),
          },
          {
            label: 'Downgrade',
            icon: 'icon_downgrade',
            isSvg: true,
            command: ({ rowData }: any) => this.openPackageForm('Downgrade', [rowData]),
          },
          {
            label: 'Delete',
            icon: 'fas fa-trash',
            command: ({ rowData }: any) => this.openPackageForm('Delete', [rowData]),
          },
        ];
        this.handleFilterObjUpdate();
        this.getPackages();
      }
    } else {
      this.navigateToNotFoundPage();
    }
  }

  /**
   * Handle filter
   */
  handleFilterObjUpdate() {
    // select all columns to the column filter
    this.filterObjectObs.subscribe((filterObject) => {
      let packages = this.util.cloneObjectArray(this.allPackages);
      if (!!filterObject) {
        if (filterObject?.isSortReset && this.dt?.datatable) {
          this.dt.datatable.sortField = null;
          this.dt.datatable.sortOrder = 1;
          this.dt.datatable.multiSortMeta = null;
          this.dt?.datatable.tableService.onSort(null);
          packages = this.util.sortObjectArray(this.util.cloneObjectArray(packages || []), 'id');
        }
        if (this.filterSearch !== filterObject.search) {
          this.dt?.datatable?.filterGlobal(filterObject.search || '', 'contains');
        }
        this.filterSearch = filterObject.search || '';
        if (filterObject?.isFiltered && !!Object.keys(filterObject?.filter || {}).length && filterObject?.filter?.status !== undefined) {
          packages = packages.filter((p) => (!!filterObject.filter?.status ? p.vulnerable_devices > 0 : p.vulnerable_devices === 0));
        }
      }
      this.values = packages;
    });
  }

  /**
   * Unselect package
   * @param event
   * @param datatable
   * @returns
   */
  unselectPackage(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedPackage = null;
  }

  /**
   * Get packages
   */
  getPackages() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.packageSrv
      .getPackages(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const packages = rs?.packages as any[];
          if (!!packages) {
            this.allPackages = packages;
            this.values = this.allPackages;
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Open package form
   * @param mode
   * @param item
   */
  openPackageForm(mode: string, item: any[] | undefined = []) {
    const dialog = this.dialogSrv.open(PackageFormComponent, {
      data: {
        mode: mode?.toLocaleLowerCase(),
        packages: item,
      },
      header: `${!mode ? 'Install' : mode} Package`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getPackages();
      }
    });
  }
}
