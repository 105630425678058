/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Observable, asyncScheduler, scheduled } from 'rxjs';
import { ILogService } from './ILogService';
import logData from './mock-data/logs.json';
import versionData from './mock-data/version.json';
import logOptionData from './mock-data/logOptions.json';

@Injectable()
export class MockLogService implements ILogService {
  getLogs(type: any, page?: any): Observable<any> {
    const log = { ...logData, page: !!page ? page : 1 };
    return scheduled([log], asyncScheduler);
  }

  getVersions(): Observable<any> {
    return scheduled([versionData], asyncScheduler);
  }

  getLogOptions(): Observable<any> {
    return scheduled([logOptionData], asyncScheduler);
  }
}
