import { FEATURES, PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { ConnectedCAsComponent } from './connected-cas/connected-cas.component';
import { DeviceEnrollmentComponent } from './device-enrollment/device-enrollment.component';
import { UserEnrollmentComponent } from './user-enrollment/user-enrollment.component';

export const ENROLMENT_SETTINGS_CONSTANTS = {
  CONNECTED_CAS: {
    ROUTE: 'connected-cas',
    LABEL: 'Connected CAs',
  },
  DEVICE_ENROLLMENT: {
    ROUTE: 'device-enrollment',
    LABEL: 'Device Enrollment',
  },
  USER_ENROLLMENT: {
    ROUTE: 'user-enrollment',
    LABEL: 'User Enrollment',
  },
};

export const ENROLMENT_SETTINGS_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: ENROLMENT_SETTINGS_CONSTANTS.CONNECTED_CAS.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: ENROLMENT_SETTINGS_CONSTANTS.CONNECTED_CAS.ROUTE,
    label: ENROLMENT_SETTINGS_CONSTANTS.CONNECTED_CAS.LABEL,
    component: ConnectedCAsComponent,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: ENROLMENT_SETTINGS_CONSTANTS.DEVICE_ENROLLMENT.ROUTE,
    label: ENROLMENT_SETTINGS_CONSTANTS.DEVICE_ENROLLMENT.LABEL,
    component: DeviceEnrollmentComponent,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.X509,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: ENROLMENT_SETTINGS_CONSTANTS.USER_ENROLLMENT.ROUTE,
    label: ENROLMENT_SETTINGS_CONSTANTS.USER_ENROLLMENT.LABEL,
    component: UserEnrollmentComponent,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.X509,
  },
];
