export const DEVICES_CONSTANTS = {
  OVERVIEW: {
    ROUTE: 'overview',
    LABEL: 'Overview',
  },
  DETAILS: {
    ROUTE: 'details',
    LABEL: 'Details',
  },
  ENROLLMENT: {
    ROUTE: 'enrollment',
    LABEL: 'Enrollment',
  },
  // REAL_TIME: {
  //   ROUTE: 'real-time',
  //   LABEL: 'Real Time',
  // },
  METRICS: {
    ROUTE: 'metrics',
    LABEL: 'Metrics',
  },
  PACKAGES: {
    ROUTE: 'packages',
    LABEL: 'Packages',
  },
  FIRMWARES: {
    ROUTE: 'firmwares',
    LABEL: 'Firmwares',
  },
  RULES: {
    ROUTE: 'rules',
    LABEL: 'Rulesets',
  },
};
