import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { GPG_KEY_ALGORITHMS, KEYRING_GPG_KEY_TYPES, KEYRING_KEY_TYPE_VALUES } from '@lcms-constants';
import { KmsService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { CREATE_LABEL, CREATE_SUCCESS, MOMENT_DATE, VALIDATOR_TYPE } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import moment from 'moment';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  LABEL: 'label',
  TYPE: 'type',
  FULL_NAME: 'full_name',
  EMAIL_ADDRESS: 'email_address',
  KEY_ALGORITHM: 'key_algorithm',
  KEY_SIZE: 'key_length',
  EXPIRATION_DATE: 'expiration_date',
  NEVER_EXPIRED: 'neverExpired',
  PASSPHRASE: 'passphrase',
  NO_PASSPHRASE: 'noPassphrase',
};

@Component({
  selector: 'app-gpg-key-form',
  templateUrl: './gpg-key-form.component.html',
  styleUrls: ['./gpg-key-form.component.scss'],
})
export class GpgKeyFormComponent extends BaseComponent implements AfterViewInit {
  keyring: any = null;

  fields: FormItem[] = [];

  FORM_PARAMS = FORM_PARAMS;

  CREATE_LABEL = CREATE_LABEL;

  @ViewChild('fb') form!: FormBuilderComponent;

  @ViewChild('neverExpireCheckboxTemplate') neverExpireCheckboxTemplate!: TemplateRef<any>;

  @ViewChild('noPassphraseTemplate') noPassphraseTemplate!: TemplateRef<any>;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.keyring = this.dialogConfig?.data?.keyring;
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        label: `Create GPG key within keyring.`,
        field: 'text',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Label',
        field: 'input',
        fieldInfo: 'Label',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TYPE,
        label: 'Type',
        field: 'dropdown',
        options: this.util.cloneObjectArray(KEYRING_GPG_KEY_TYPES),
        placeholder: 'Select a key type',
        fieldInfo: 'Type',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.FULL_NAME,
        label: 'Full Name',
        field: 'input',
        fieldInfo: 'Full name',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EMAIL_ADDRESS,
        label: 'Email Address',
        field: 'input',
        fieldInfo: 'Email address',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_ALGORITHM,
        label: 'Key Algorithm',
        field: 'dropdown',
        options: this.util.cloneObjectArray(GPG_KEY_ALGORITHMS),
        placeholder: 'Select a key algorithm',
        fieldInfo: 'Key algorithm',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_SIZE,
        label: 'Key Size',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a key size',
        fieldInfo: 'Key size',
        disabled: true,
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EXPIRATION_DATE,
        label: 'Expiration Date',
        field: 'calendar',
        calendarMinDate: new Date(),
        afterFieldTemplate: this.neverExpireCheckboxTemplate,
        fieldInfo: 'Expiration date',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NEVER_EXPIRED,
        defaultValue: false,
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSPHRASE,
        label: 'Passphrase',
        field: 'password',
        afterFieldTemplate: this.noPassphraseTemplate,
        fieldInfo: 'Passphrase',
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.NO_PASSPHRASE,
        defaultValue: false,
        hidden: true,
      } as FormItem),
    ];
    this.fields = fields;
    this.setupChangeEvents();
    this.form?.patchValue({ [FORM_PARAMS.TYPE]: KEYRING_KEY_TYPE_VALUES.GPG });
    this.form?.disableControl(FORM_PARAMS.TYPE);
  }

  setupChangeEvents() {
    // Type
    this.form.setChangeEvent(FORM_PARAMS.TYPE, (type) => {
      const validators = type === KEYRING_KEY_TYPE_VALUES.GPG ? [VALIDATOR_TYPE.REQUIRED] : [];
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.FULL_NAME, validators);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.EMAIL_ADDRESS, validators);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.EXPIRATION_DATE, validators);
      this.form.setControlValidatorsAndVisibility(FORM_PARAMS.PASSPHRASE, validators);
      this.form.enableControl(FORM_PARAMS.KEY_ALGORITHM);
    });
    // Key algorithm
    this.form.setChangeEvent(FORM_PARAMS.KEY_ALGORITHM, (keyAlgorithm) => {
      const keySizeField = this.fields.find((p) => p.name === FORM_PARAMS.KEY_SIZE);
      if (!!keySizeField) {
        keySizeField.options = this.util.cloneObjectArray(GPG_KEY_ALGORITHMS).find((p) => p.value === keyAlgorithm)?.options;
        if (!!keySizeField.options?.length) {
          this.form.enableControl(FORM_PARAMS.KEY_SIZE);
        } else {
          this.form.disableControl(FORM_PARAMS.KEY_SIZE);
        }
        this.form.setControlValue(FORM_PARAMS.KEY_SIZE, null);
      }
    });
    // Expiration Date
    this.form.setChangeEvent(FORM_PARAMS.NEVER_EXPIRED, (isNeverExpired) => {
      this.form.setControlValidators(FORM_PARAMS.EXPIRATION_DATE, !isNeverExpired ? [VALIDATOR_TYPE.REQUIRED] : []);
      if (!!isNeverExpired) {
        this.form.disableControl(FORM_PARAMS.EXPIRATION_DATE);
      } else {
        this.form.enableControl(FORM_PARAMS.EXPIRATION_DATE);
      }
    });
    // Passphrase
    this.form.setChangeEvent(FORM_PARAMS.NO_PASSPHRASE, (hasNoPassphrase) => {
      this.form.setControlValidators(FORM_PARAMS.PASSPHRASE, !hasNoPassphrase ? [VALIDATOR_TYPE.REQUIRED] : []);
      if (!!hasNoPassphrase) {
        this.form.disableControl(FORM_PARAMS.PASSPHRASE);
      } else {
        this.form.enableControl(FORM_PARAMS.PASSPHRASE);
      }
    });
  }

  /**
   * Submit the form
   * @param callback
   */
  onSubmit(callback: (rs: any) => void) {
    const payload = this.util.cloneDeepObject(this.form?.getRawValue());
    this.preparePayload(payload);
    this.kmsSrv
      .createGPGKey(this.keyring?.id, payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const message = CREATE_SUCCESS.replace('{0}', 'GPG Key');
          this.showSuccessMessage(message);
          callback(rs);
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Prepare the payload before the submission
   * @param payload
   */
  preparePayload(payload: any) {
    // Expiration date
    payload[FORM_PARAMS.EXPIRATION_DATE] = !!payload[FORM_PARAMS.NEVER_EXPIRED]
      ? null
      : moment(payload[FORM_PARAMS.EXPIRATION_DATE]).format(MOMENT_DATE);
    delete payload[FORM_PARAMS.NEVER_EXPIRED];
    // Passphrase
    payload[FORM_PARAMS.PASSPHRASE] = !!payload[FORM_PARAMS.NO_PASSPHRASE] ? null : payload[FORM_PARAMS.PASSPHRASE];
    if (!payload[FORM_PARAMS.PASSPHRASE]) {
      delete payload[FORM_PARAMS.PASSPHRASE];
    }
    delete payload[FORM_PARAMS.NO_PASSPHRASE];
  }
}
