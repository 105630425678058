import { IDeviceCertificateService } from './IDeviceCertificateService';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { asyncScheduler, Observable, of, scheduled } from 'rxjs';
import certificatesData from './mock-data/device-certificates.json';

@Injectable({
  providedIn: 'root',
})
export class MockDeviceCertificateService implements IDeviceCertificateService {
  getCertificates(organizationId: any, projectId: any, kmsKeyId?: any): Observable<any> {
    return scheduled([certificatesData], asyncScheduler);
  }

  getTotalCertificates(organizationId: any, projectId: any): Observable<any> {
    const data = certificatesData;
    return of(data.certificates.length);
  }

  getCertificate(certId: any): Observable<any> {
    const data = certificatesData;
    return scheduled([(data.certificates as any[]).find((p) => p.id === certId)], asyncScheduler);
  }

  updateCertificate(certId: any, payload: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }

  getCertificatePrivateKey(certId: any): Observable<any> {
    return scheduled([{}], asyncScheduler);
  }
}
