import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { CA_MODE_VALUES } from '@lcms-constants';
import { RepositoryService } from '@lcms-services';
import { BaseComponent, FormBuilderComponent } from '@microsec/components';
import { MEDIUM_TEXT_MAX_LENGTH, VALIDATOR_TYPE } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { finalize } from 'rxjs';

const CA_FIELDS = {
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  CA_TEMPLATE_NAME: 'ca_template_name',
};

const FIELD_NAMES = {
  LCMS_HOST: 'LCMS Server Hostname',
  LCMS_PORT: 'LCMS Server Port',
  MQTT_ADDRESS: 'MQTT Broker Address',
  PROJECT_ID: 'Project ID',
};

@Component({
  selector: 'app-device-agent-form',
  templateUrl: './device-agent-form.component.html',
  styleUrls: ['./device-agent-form.component.scss'],
})
export class DeviceAgentFormComponent extends BaseComponent implements AfterViewInit {
  isLoading = false;

  projectId: any = null;

  formArray: any[] = [];

  @ViewChildren('fb') forms: QueryList<FormBuilderComponent>;

  formObjectArray: any[] = [];

  caServers: any[] = [];

  constructor(private repoSrv: RepositoryService) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.projectId = this.breadcrumbConfig?.projectId;
    this.repoSrv.getAgentForm(this.projectId).subscribe({
      next: (formData) => {
        this.formArray = ((formData?.form_components as any[]) || []).map((p) => ({ ...p, isToggled: false, toggleDisabled: !p.toggleable }));
        this.initAgentForms(this.formArray);
        setTimeout(() => {
          this.getForms(this.formArray);
          this.setChangedEvents();
        });
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Initialize the Agent form
   * @param formArray
   */
  initAgentForms(formArray: any[]) {
    formArray.forEach((formGroup: any) => {
      const children = (formGroup?.children as any[]) || [];
      if (!children.filter((p) => p.type === 'tab').length) {
        const fields = children.map((rawItem) => this.getFormItem(rawItem)) || [];
        this.initCAServerFields(fields);
        fields.forEach((field) => field.setMediumSize());
        formGroup.fields = fields;
      } else {
        formGroup.tabs = children;
        this.initAgentForms(formGroup.tabs);
      }
    });
  }

  /**
   * If field CA Server exits, show its related fields
   * @param fields
   */
  initCAServerFields(fields: FormItem[]) {
    fields.forEach((field, index, self) => {
      if (field.name === CA_FIELDS.CA_SERVER_ID) {
        const caField = Object.assign(new FormItem(), {
          name: CA_FIELDS.CA_ID,
          label: 'CA Certificate',
          field: 'dropdown',
          options: [] as any[],
          placeholder: 'Select a CA certificate',
          disabled: true,
        } as FormItem);
        const caTemplateField = Object.assign(new FormItem(), {
          name: CA_FIELDS.CA_TEMPLATE_NAME,
          label: 'CA Template',
          field: 'dropdown',
          options: [] as any[],
          placeholder: 'Select a CA template',
          disabled: true,
        } as FormItem);
        const relatedFields: FormItem[] = [caField, caTemplateField];
        self.splice(index + 1, 0, ...relatedFields);
      }
    });
  }

  /**
   * Render form items
   * @param rawItem
   * @returns
   */
  private getFormItem(rawItem: any) {
    if (rawItem?.id === CA_FIELDS.CA_SERVER_ID) {
      this.caServers = (rawItem?.children as any[]) || [];
    }
    return Object.assign(new FormItem(), {
      name: rawItem?.id,
      label: rawItem?.name,
      field: this.parseFieldType(rawItem?.type),
      options: ((rawItem?.children as any[]) || []).map((p) => ({ value: p?.id, label: p?.name, disabled: !!p?.disabled })),
      placeholder: this.getFieldPlaceholder(rawItem),
      fieldInfo: rawItem?.description || rawItem?.name,
      defaultValue: this.getDefaultFieldValue(rawItem),
      required: rawItem?.mandatory,
      pattern: rawItem?.regex || undefined,
      patternErrorText: rawItem?.regex_error_text || undefined,
      disabled: !!rawItem?.requires?.length,
      ...(rawItem?.type === 'text_input' ? { maxInputLimit: MEDIUM_TEXT_MAX_LENGTH } : {}),
    } as FormItem);
  }

  private getDefaultFieldValue(rawItem: any) {
    switch (rawItem?.name) {
      case FIELD_NAMES.PROJECT_ID: {
        return this.breadcrumbConfig?.projectId || rawItem?.default || '1';
      }
      case FIELD_NAMES.LCMS_HOST: {
        return window?.location?.hostname;
      }
      case FIELD_NAMES.LCMS_PORT: {
        return window?.location?.port;
      }
      case FIELD_NAMES.MQTT_ADDRESS: {
        return 'ssl://' + window?.location?.hostname + ':8883';
      }
      default: {
        // check if it is type checkbox if not use default rawItem value, if it is checkbox always default to false
        return rawItem?.type !== 'checkbox' ? rawItem?.default || null : false;
      }
    }
  }

  /**
   * Parse the field type
   * @param fieldType
   */
  private parseFieldType(fieldType: any) {
    switch (fieldType) {
      case 'text_input': {
        return 'input';
      }
      case 'number_input': {
        return 'number';
      }
      case 'password_input': {
        return 'password';
      }
      default: {
        return fieldType;
      }
    }
  }

  /**
   * Get forms
   * @param formArray
   */
  getForms(formArray: any[]) {
    formArray.forEach((formGroup) => {
      const foundFormGroup = this.getForm(formGroup.id);
      if (!!foundFormGroup) {
        formGroup.form = foundFormGroup;
        this.formObjectArray.push(formGroup);
      }
      if (!!formGroup?.tabs?.length) {
        if (!!formGroup?.requires?.length) {
          formGroup.tabs.forEach((tab: any) => {
            tab.requires = formGroup.requires;
            tab.disabled = !!tab?.exclude_if?.length;
          });
        }
        this.getForms(formGroup.tabs);
      }

      // Add toggleable formGroup to set Form level Change Event Callback
      if (!!formGroup?.toggleable) {
        this.formObjectArray.push(formGroup);
      }
    });
  }

  /**
   * Set change events for the forms
   */
  setChangedEvents() {
    let eventChangesObjects: any[] = [];
    this.formObjectArray.forEach((formGroup) => {
      const form: FormBuilderComponent = formGroup.form;
      if (!!form) {
        // Form level
        eventChangesObjects = this.getChangeEventsInFormLevel(formGroup, eventChangesObjects);
        // Field level
        eventChangesObjects = this.getChangeEventsInFieldLevel(formGroup, eventChangesObjects);
        // Option level
        eventChangesObjects = this.getChangeEventsInOptionLevel(formGroup, eventChangesObjects);
      } else {
        // Form level for toggleable Form
        eventChangesObjects = this.getChangeEventsInFormLevel(formGroup, eventChangesObjects);
      }
    });

    eventChangesObjects.forEach((eventChangesObject) => {
      const fieldArray = ((eventChangesObject.field as string) || '')?.split('.');
      const formName = fieldArray?.[0];
      const fieldName = fieldArray?.[1];
      this.getForm(formName)?.setChangeEvent(fieldName, (changedValue: any) => {
        ((eventChangesObject.callbackArray as any[]) || []).forEach((callback) => {
          callback(changedValue);
        });
      });
    });

    // Disable required forms at the start
    this.formObjectArray.forEach((formGroup) => {
      const form: FormBuilderComponent = formGroup.form;
      if (!!formGroup?.requires?.length) {
        form.disable();
      }
    });
  }

  /**
   * Get change events in form level
   * @param formGroup
   * @param eventChangesObjects
   */
  private getChangeEventsInFormLevel(formGroup: any, eventChangesObjects: any[]) {
    const form: FormBuilderComponent = formGroup.form;
    // Check requires
    if (!!formGroup?.requires?.length) {
      formGroup.requires.forEach((requiredField: any) => {
        const foundEventChangesObject = eventChangesObjects.find((p) => p.field === requiredField);
        const changeFormLevelEvent = () => {
          let condition = true;
          formGroup.requires.forEach((requiredItem: any) => {
            const requiredItemArray = requiredItem?.split('.');
            const formName = requiredItemArray?.[0];
            const fieldName = requiredItemArray?.[1];
            condition = condition && this.getForm(formName)?.getControlValue(fieldName);
          });

          if (!!condition) {
            form.enable();
          } else {
            form.disable();
          }

          // Refresh all the fields if enabled, to make them disable again the form requires
          formGroup.children.forEach((fieldItem: any) => {
            form.getControl(fieldItem.id)?.updateValueAndValidity();
          });
        };
        if (!foundEventChangesObject) {
          eventChangesObjects.push({ field: requiredField, callbackArray: [changeFormLevelEvent] });
        } else {
          foundEventChangesObject.callbackArray.push(changeFormLevelEvent);
        }
      });
    }
    // Check exclude_if
    if (!!formGroup?.exclude_if?.length) {
      formGroup.exclude_if.forEach((excludeIfField: any) => {
        const foundEventChangesObject = eventChangesObjects.find((p) => p.field === excludeIfField);
        const changeFormLevelEvent = () => {
          let excludeCondition = false;
          formGroup.exclude_if.forEach((requiredItem: any) => {
            const requiredItemArray = requiredItem?.split('.');
            const formName = requiredItemArray?.[0];
            const fieldName = requiredItemArray?.[1];
            const fieldValue = requiredItemArray?.[2].replace('*', '');

            excludeCondition =
              excludeCondition ||
              this.getForm(formName)
                ?.getControlValue(fieldName)
                ?.startsWith(fieldValue as string);
          });

          // If Form is Toggleable and have exclude_if, we handle state appropriately
          if (!!formGroup.toggleable) {
            // isToggled already false maintain the state
            formGroup.isToggled = formGroup.isToggled && !excludeCondition;
            formGroup.toggleDisabled = excludeCondition;
          }

          formGroup.disabled = excludeCondition;
          formGroup.children.forEach((item: any) => {
            if (!!formGroup.disabled) {
              form.disableControl(item.id);
            } else {
              form.enableControl(item.id);
            }
          });
        };

        if (!foundEventChangesObject) {
          eventChangesObjects.push({ field: excludeIfField, callbackArray: [changeFormLevelEvent] });
        } else {
          foundEventChangesObject.callbackArray.push(changeFormLevelEvent);
        }
      });
    }
    return eventChangesObjects;
  }

  /**
   * Get change events in field level
   * @param formGroup
   * @param eventChangesObjects
   */
  private getChangeEventsInFieldLevel(formGroup: any, eventChangesObjects: any[]) {
    const fieldItems: any[] = formGroup.children;
    const form: FormBuilderComponent = formGroup.form;
    fieldItems.forEach((fieldItem) => {
      // Check requires
      if (!!fieldItem?.requires?.length) {
        fieldItem.requires.forEach((requiredField: any) => {
          const foundEventChangesObject = eventChangesObjects.find((p) => p.field === requiredField);
          const changeFieldLevelEvent = () => {
            let condition = true;
            fieldItem.requires.forEach((requiredItem: any) => {
              const requiredItemArray = requiredItem?.split('.');
              const formName = requiredItemArray?.[0];
              const fieldName = requiredItemArray?.[1];
              condition = condition && !!this.getForm(formName)?.getControlValue(fieldName);
            });
            if (!!condition) {
              form.getControl(fieldItem.id)?.enable();
            } else {
              form.getControl(fieldItem.id)?.disable();
            }
          };
          if (!foundEventChangesObject) {
            eventChangesObjects.push({ field: requiredField, callbackArray: [changeFieldLevelEvent] });
          } else {
            foundEventChangesObject.callbackArray.push(changeFieldLevelEvent);
          }
        });
      }
      // Check exclude if
      if (!!fieldItem?.exclude_if?.length) {
        fieldItem.exclude_if.forEach((excludeIfItem: any) => {
          const excludeIfItemArray: string[] = excludeIfItem?.replace('!', '')?.split('.') || [];
          const excludeField = (
            formGroup.type === 'tab'
              ? `${excludeIfItemArray?.[1]}.${excludeIfItemArray?.[2]}`
              : `${excludeIfItemArray?.[0]}.${excludeIfItemArray?.[1]}`
          )
            .replace('!', '')
            .replace('*', '');
          const foundEventChangesObject = eventChangesObjects.find((p) => p.field === excludeField);
          const changeFieldLevelEvent = (changedValue: any) => {
            let excludeCondition = false;
            fieldItem.exclude_if.forEach((excludedItem: any) => {
              const excludedItemArray: string[] = excludedItem?.split('.') || [];
              const isStarCondition = excludedItemArray?.[excludedItemArray?.length - 1]?.indexOf('*') > -1;
              if (formGroup.type === 'tab') {
                excludedItemArray?.shift();
              }
              const fieldValue = excludedItemArray?.[2]?.replace('*', '') || false;
              let valueCondition = false;
              // Check start with
              if (!isStarCondition) {
                valueCondition = changedValue === fieldValue;
              } else {
                valueCondition = (changedValue as string).startsWith(fieldValue as string);
              }
              excludeCondition = excludeCondition || !!valueCondition;
            });
            // If exclude_if condition exists in requires conditions and the exclude_if happens, set not required
            if (!!fieldItem?.requires?.length) {
              form.setControlValidatorsAndVisibility(fieldItem.id, !excludeCondition ? [VALIDATOR_TYPE.REQUIRED] : []);
              if (!excludeCondition) {
                form.enableControl(fieldItem.id);
              } else {
                form.disableControl(fieldItem.id);
              }
            } else {
              form.setControlVisibility(fieldItem.id, !excludeCondition);
            }
          };
          if (!foundEventChangesObject) {
            eventChangesObjects.push({ field: excludeField, callbackArray: [changeFieldLevelEvent] });
          } else {
            foundEventChangesObject.callbackArray.push(changeFieldLevelEvent);
          }
        });
      }
      // CA Server only
      if (fieldItem?.id === CA_FIELDS.CA_SERVER_ID) {
        const caField = ((formGroup.fields as FormItem[]) || []).find((p) => p.name === CA_FIELDS.CA_ID);
        const caTemplateField = ((formGroup.fields as FormItem[]) || []).find((p) => p.name === CA_FIELDS.CA_TEMPLATE_NAME);
        if (!!caField && !!caTemplateField) {
          form.setChangeEvent(CA_FIELDS.CA_SERVER_ID, (caServerId) => {
            const caServer = this.caServers.find((p) => p.id === caServerId);
            if (!!caServer?.cas?.length) {
              caField.options = ((caServer.cas as any[]) || [])
                .filter((p: any) => p.mode === CA_MODE_VALUES.X509)
                .filter((p: any) => (p.type as string).includes('intermediate'))
                .map((p) => ({ value: p.id, label: p?.subject?.CN, caTemplates: p.ca_templates }));
              form.enableControl(CA_FIELDS.CA_ID);
            } else {
              form.disableControl(CA_FIELDS.CA_ID);
            }
          });
          form.setChangeEvent(CA_FIELDS.CA_ID, (caId) => {
            const caTemplates: any[] = caField.options.find((p) => p.value === caId)?.caTemplates || [];
            if (!!caTemplates?.length) {
              caTemplateField.options = caTemplates.map((p) => ({ value: p.name, label: p.name }));
              form.enableControl(CA_FIELDS.CA_TEMPLATE_NAME);
            } else {
              form.disableControl(CA_FIELDS.CA_TEMPLATE_NAME);
            }
          });
        }
      }
    });
    return eventChangesObjects;
  }

  /**
   * Get change events in option level
   * @param formGroup
   * @param eventChangesObjects
   */
  getChangeEventsInOptionLevel(formGroup: any, eventChangesObjects: any[]) {
    const dropdownItems: any[] = ((formGroup.children as any[]) || []).filter((p) => p.type === 'dropdown');
    const dropdownFields: FormItem[] = ((formGroup.fields as any[]) || []).filter((p) => p.field === 'dropdown');

    dropdownItems.forEach((dropdownItem: any, index: number) => {
      const dropDownOptions: any[] = dropdownItem?.children || [];

      const excludeIfStringArray: string[] = [];
      dropDownOptions.forEach((option: any) => {
        if (option?.exclude_if?.length) {
          excludeIfStringArray.push(...option.exclude_if);
        }
      });
      // Get exclude fields
      const formFieldNames: string[] = [];
      excludeIfStringArray.forEach((excludeIfItem: any) => {
        const excludeIfItemArray: string[] = excludeIfItem?.split('.') || [];
        const excludeField =
          this.formObjectArray.find((p) => p.type === excludeIfItemArray?.[1])?.type === 'tab'
            ? `${excludeIfItemArray?.[1]}.${excludeIfItemArray?.[2]}`
            : `${excludeIfItemArray?.[0]}.${excludeIfItemArray?.[1]}`;
        if (!formFieldNames.includes(excludeField)) {
          formFieldNames.push(excludeField);
        }
      });
      // Create event changes
      formFieldNames.forEach((formFieldName) => {
        const foundEventChangesObject = eventChangesObjects.find((p) => p.field === formFieldName);
        const changeOptionLevelEvent = () => {
          const relatedExcludeIfStringOptions = excludeIfStringArray.filter((p) => p.indexOf(formFieldName) > -1);
          const excludeConditions: any[] = [];
          relatedExcludeIfStringOptions.forEach((relatedExcludeIfStringOption) => {
            const excludedItemArray: string[] = relatedExcludeIfStringOption?.split('.') || [];
            const isStarCondition = excludedItemArray?.[excludedItemArray?.length - 1]?.indexOf('*') > -1;
            if (this.formObjectArray.find((p) => p.type === excludedItemArray?.[1])?.type === 'tab') {
              excludedItemArray?.shift();
            }
            const formName = excludedItemArray?.[0];
            const fieldName = excludedItemArray?.[1];
            const fieldValue = excludedItemArray?.[2]?.replace('*', '');
            let valueCondition = false;
            // Check start with
            if (!isStarCondition) {
              valueCondition = this.getForm(formName)?.getControlValue(fieldName) === fieldValue;
            } else {
              valueCondition = (this.getForm(formName)?.getControlValue(fieldName) as string).startsWith(fieldValue as string);
            }
            if (!!valueCondition) {
              excludeConditions.push(relatedExcludeIfStringOption);
            }
          });
          const resultOptions: any[] = [];
          dropDownOptions.forEach((item) => {
            let result = true;
            excludeConditions.forEach((excludeCondition) => {
              if (((item?.exclude_if as any[]) || [])?.includes(excludeCondition)) {
                result = false;
              }
            });
            if (!!result) {
              resultOptions.push({ value: item?.id, label: item?.name, disabled: !!item?.disabled });
            }
          });
          dropdownFields[index].options = resultOptions;
        };
        if (!foundEventChangesObject) {
          eventChangesObjects.push({ field: formFieldName, callbackArray: [changeOptionLevelEvent] });
        } else {
          foundEventChangesObject.callbackArray.push(changeOptionLevelEvent);
        }
      });
    });
    return eventChangesObjects;
  }

  /**
   * Get form
   * @param formId
   */
  getForm(formId: string): FormBuilderComponent | undefined {
    return this.forms.find((p) => p.id === formId);
  }

  /**
   * Get placeholder rof the field
   * @param rawItem
   */
  private getFieldPlaceholder(rawItem: any) {
    switch (rawItem?.type) {
      case 'dropdown': {
        const name: string = rawItem?.name || '';
        const isVowel = ['u', 'e', 'o', 'a', 'i'].includes((name?.[0] || '').toLowerCase());
        const isPlural = name?.[name?.length - 1] === 's';
        const transformText = (text: string) => {
          let words = text.split(' ');
          words = words.map((word) => {
            if (word !== word.toUpperCase()) {
              word = word.toLowerCase();
            }
            return word;
          });
          return words.join(' ');
        };
        return `Select${isPlural ? '' : isVowel ? ' an' : ' a'} ${transformText(name)}`;
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Download agent
   */
  submitAgentForm() {
    this.isLoading = true;
    const formPayload: any = {};
    this.formArray.forEach((header) => {
      formPayload[header.id] = {};
      const headerChildren: any[] = header?.children || [];
      // No tab
      if (!headerChildren.filter((p) => p.type === 'tab').length) {
        formPayload[header.id] = this.getFormValue(header?.id);
      }
      // Has tab
      else {
        if (!!header?.toggleable && !!header?.isToggled) {
          headerChildren.forEach((tab) => {
            if (!tab?.disabled) {
              formPayload[header.id][tab.id] = this.getFormValue(tab?.id);
            }
          });
          formPayload['run_time_configuration'] = true;
        } else {
          delete formPayload[header.id];
          formPayload['run_time_configuration'] = false;
        }
      }
    });
    const flattenPayload = this.getFlattenPayload(formPayload);
    this.repoSrv
      .downloadAgent(flattenPayload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (rs) => {
          if (!!rs) {
            this.util.downloadFileFromBlob(rs);
            this.showSuccessMessage('Downloading agent');
          } else {
            this.showErrorMessage('Cannot download agent');
          }
        },
        error: (err) => {
          this.forms.forEach((form) => {
            form.showServerErrorMessage(err);
          });
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get values from form
   */
  getFormValue(formId: any) {
    return this.getForm(formId)?.getValue();
  }

  /**
   * Get flatten payload
   * @param payload
   * @param lastPayload
   * @param lastKey
   */
  getFlattenPayload(payload: any, lastPayload: any = null, lastKey: any = null) {
    const newPayload: any = !!lastPayload ? lastPayload : {};
    if (typeof payload === 'object') {
      Object.entries(payload || {}).forEach(([key, value]) => {
        const combinedKey = !lastKey ? key : `${lastKey}.${key}`;
        if (typeof value === 'object') {
          this.getFlattenPayload(value, newPayload, combinedKey);
        } else {
          newPayload[combinedKey] = this.getFlattenPayload(value, newPayload, combinedKey);
        }
      });
    } else {
      return payload;
    }
    return newPayload;
  }

  get isSubmitButtonDisabled() {
    return !!this.formObjectArray.find((p) => {
      // if it is form with switch, by-pass validation
      if (p.toggleable) {
        return false;
      }
      return !p.disabled && !p?.form?.valid;
    });
  }
}
