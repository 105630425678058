<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="w-full" style="padding: 1rem 1.5rem">
    @switch (step) {
      @case (0) {
        <app-recommendation (changeStepEvent)="changeStep($event)" />
      }
      @case (1) {
        <app-qr-code-scan [qrData]="qrData" (changeStepEvent)="changeStep($event)" />
      }
      @case (2) {
        <app-authenticator-verification (changeStepEvent)="changeStep($event)" />
      }
      @case (3) {
        <app-verification-codes [codes]="codes" (changeStepEvent)="changeStep($event)" />
      }
      @default {
        <!-- SUCCESS -->
        <div class="grid mt-5 py-5">
          <div class="col-12 text-center">
            <fa-icon [icon]="faCheckCircle" />
          </div>

          <label class="col-12 text-data text-center">
            <strong>SUCCESS</strong>
          </label>

          <label class="col-12 text-data text-center"> 2FA has been set up for your account. </label>
        </div>

        <div class="grid">
          <div class="col-6 text-left">
            <p-button severity="secondary" (onClick)="changeStep(-1)" label="Back" />
          </div>
          <div class="col-6 text-right">
            <p-button severity="success" [autofocus]="true" (onClick)="changeStep(0)" label="Finish" />
          </div>
        </div>
      }
    }
  </div>
</microsec-loading-overlay>
