import { PROJECT_SETTINGS_CHILDREN as COMMON_PROJECT_SETTINGS_CHILDREN, PROJECT_SETTINGS_CONSTANTS } from '@lcms-products';
import { ILayoutItem } from '@microsec/interfaces';
import { SharedLabelsComponent } from '../../../shared-components/shared-labels/shared-labels.component';

export const PROJECT_SETTINGS_CHILDREN: ILayoutItem[] = [
  ...COMMON_PROJECT_SETTINGS_CHILDREN,
  {
    route: PROJECT_SETTINGS_CONSTANTS.LABELS.ROUTE,
    label: PROJECT_SETTINGS_CONSTANTS.LABELS.LABEL,
    component: SharedLabelsComponent,
    visible: false,
  },
];
