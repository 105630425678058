@if (!selectedDevice) {
  <!-- PROJECT -->
  <microsec-layout-table
    [itemName]="type"
    [isLoading]="isLoading"
    [shouldShowCreateButton]="true"
    (openFormEvent)="openForm()"
    [toolbarActionButtons]="toolbarActionButtons"
    [filterKey]="constructor.name"
    [cols]="cols"
    [(selectedItem)]="selectedRuleset"
    [values]="values"
    [cellTemplate]="cellTemplate"
    [actionMenuItems]="actionMenuItems()"
    [detailsTemplate]="detailsTemplate"
    (getItemsEvent)="getRulesets()" />
} @else {
  <!-- Link to create or assign ruleset -->
  <div class="font-italic text-white text-center mb-3">
    Create the Firewall {{ type }} and assign this device to the ruleset <a class="link" [href]="rulesetLink">here</a>.
  </div>
  <!-- DEVICE -->
  <microsec-layout-table
    [itemName]="type"
    [isLoading]="isLoading"
    [toolbarActionButtons]="[]"
    [filterKey]="constructor.name"
    [cols]="cols"
    [selectedItem]="null"
    [values]="values"
    [cellTemplate]="cellTemplate"
    [detailsTemplate]="null"
    (getItemsEvent)="getRulesets()" />
}

<ng-template #cellTemplate let-col="col" let-rowData="rowData">
  @switch (col.field) {
    @case ('enabled') {
      <p-inputSwitch
        [(ngModel)]="rowData[col.field]"
        (click)="$event.stopPropagation()"
        (onChange)="updateRuleset(rowData, { enabled: $event.checked })"
        [disabled]="!!selectedDevice" />
    }
    @default {
      <microsec-truncate-text>
        {{ rowData[col.field] || '-' }}
      </microsec-truncate-text>
    }
  }
</ng-template>

<ng-template #detailsTemplate>
  <microsec-card [isNoPadding]="true">
    <microsec-details-panel [title]="selectedRuleset?.name" [buttons]="panelButtons()" (collapseEvent)="selectedRuleset = null">
      <app-ruleset-info
        [selectedRuleset]="selectedRuleset"
        [type]="_type"
        (updateRulesetEvent)="updateRuleset(selectedRuleset, $event)"
        (exportRulesetEvent)="export($event)" />
    </microsec-details-panel>
  </microsec-card>
</ng-template>
