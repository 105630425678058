import { Component, Input, OnInit, WritableSignal, computed, signal } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { ActionMenuItem, ConfirmationDialogConfig, DetailsPanelButtonModel } from '@microsec/models';
import { RulesetFormComponent } from './ruleset-form/ruleset-form.component';
import { CREATE_LABEL, DELETE_LABEL, EDIT_LABEL } from '@microsec/constants';
import { RuleService } from '@lcms-services';
import { Observable, finalize } from 'rxjs';
import { RulesetImportFormComponent } from './ruleset-import-form/ruleset-import-form.component';
import { DEVICES_CONSTANTS } from '../../../project-management/overview/devices/devices.constants';

@Component({
  selector: 'app-rulesets',
  templateUrl: './rulesets.component.html',
  styleUrls: ['./rulesets.component.scss'],
})
export class RulesetsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _type = '';

  get type() {
    return `${this._type} Ruleset`;
  }

  @Input() set type(value: string) {
    this._type = value;
  }

  _selectedDevice: any = null;

  @Input() set selectedDevice(value: any) {
    this._selectedDevice = value;
    this.actionMenuItems.set([]);
    this.toolbarActionButtons = [];
    this.getRulesets();
  }

  get selectedDevice() {
    return this._selectedDevice;
  }

  cols: any[] = [];

  selectedRuleset: any = null;

  values: any[] = [];

  toolbarActionButtons: any[] = [];

  actionMenuItems: WritableSignal<ActionMenuItem[]> = signal([
    {
      label: 'Export',
      icon: 'fas fa-file-export',
      command: ({ rowData }: any) => this.export(rowData),
    },
    {
      label: 'Edit',
      icon: 'fas fa-edit',
      command: ({ rowData }: any) => this.openForm(rowData),
    },
    {
      label: 'Delete',
      icon: 'fas fa-trash',
      command: ({ rowData }: any) => this.confirmDeletion(rowData),
    },
  ]);

  panelButtons = computed(() => {
    const items: ActionMenuItem[] = this.actionMenuItems();
    return items.map(
      (item) =>
        ({
          label: item.label,
          icon: item.icon,
          command: () => {
            item?.command?.({ rowData: this.selectedRuleset } as any);
          },
        }) as DetailsPanelButtonModel,
    );
  });

  constructor(private ruleSrv: RuleService) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    const cols: any[] = [
      { field: 'id', header: 'ID', width: 5 },
      { field: 'name', header: 'Name', width: 10 },
      { field: 'enabled', header: 'Enabled', width: 5 },
    ];
    if (!this.selectedDevice) {
      cols.push({ field: 'total_device_count', header: 'Assigned Devices', width: 10 });
      this.toolbarActionButtons = [
        {
          severity: 'primary',
          label: `Import ${this.type}`,
          icon: 'fa fa-file-import',
          callback: () => this.openImportForm(),
        },
      ];
    }
    this.cols = cols;
    this.getRulesets();
  }

  /**
   * Get rulesets
   */
  async getRulesets() {
    await this.prepareConfigs();
    this.isLoading = true;
    this.ruleSrv
      .getRulesets(this.breadcrumbConfig?.projectId, this.selectedDevice?.id, this._type)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (results: any) => {
          this.values = results;
          if (!!this.selectedRuleset) {
            this.selectedRuleset = this.values.find((p) => p.id === this.selectedRuleset?.id);
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open ruleset form
   * @param ruleset
   */
  openForm(ruleset?: any) {
    const dialog = this.dialogSrv.open(RulesetFormComponent, {
      header: `${!ruleset ? CREATE_LABEL : EDIT_LABEL} ${this.type}`,
      data: { ruleset, type: this._type },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getRulesets();
      }
    });
  }

  /**
   * Update ruleset
   * @param ruleset
   * @param changedObject
   */
  updateRuleset(ruleset: any, changedObject: any) {
    let request = new Observable<any>();
    const firstKey = Object.keys(changedObject)?.[0] || '';
    switch (firstKey) {
      case 'enabled': {
        request = this.ruleSrv.updateRuleset(ruleset?.id, { ...changedObject });
        break;
      }
      case 'add': {
        request = this.ruleSrv.assignDevice(ruleset?.id, { device_ids: [changedObject.add] });
        break;
      }
      case 'remove': {
        request = this.ruleSrv.removeDevice(ruleset?.id, { device_ids: [changedObject.remove] });
        break;
      }
    }
    request.subscribe({
      next: () => {
        this.showSuccessMessage(`Updated ruleset ${ruleset?.name} successfully`);
        this.getRulesets();
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Confirm deletion
   * @param ruleset
   */
  confirmDeletion(ruleset?: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Ruleset',
      object: ruleset,
      objectFieldName: 'name',
      acceptRequest: this.ruleSrv.deleteRuleset(ruleset?.id),
      next: () => {
        this.showSuccessMessage(`Deleted ruleset ${ruleset?.name} successfully`);
        this.selectedRuleset = null;
        this.getRulesets();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Export ruleset
   * @param ruleset
   */
  export(ruleset?: any) {
    this.ruleSrv.exportRuleset(ruleset?.id).subscribe({
      next: (rs) => {
        this.util.downloadFileFromBlob(rs, `ruleset_${ruleset?.id}_${ruleset?.name}.json`);
        this.showSuccessMessage(`Downloaded Ruleset ${ruleset?.name} successfully`);
      },
      error: (err) => {
        this.showErrorMessage(err);
      },
    });
  }

  /**
   * Open import form
   */
  openImportForm() {
    const dialog = this.dialogSrv.open(RulesetImportFormComponent, {
      header: `Import ${this.type}`,
      data: { type: this._type },
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getRulesets();
      }
    });
  }

  /**
   * Link to specific ruleset page to assign to device
   */
  get rulesetLink() {
    return `${window.location.protocol}//${this.currentDomain}${window.location.pathname}/${
      DEVICES_CONSTANTS.RULES.ROUTE
    }/${this._type.toLowerCase()}`.replace(`${DEVICES_CONSTANTS.DETAILS.ROUTE}/`, '');
  }
}
