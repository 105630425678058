<microsec-loading-overlay [isLoading]="isLoading">
  <div class="grid">
    <div class="col-12">
      @for (field of fields; track $index; let last = $last) {
        <p-button
          [styleClass]="'text-transform-none ' + (!last ? 'mr-2' : '')"
          [severity]="selectedField === field.value ? 'primary' : 'secondary'"
          [rounded]="true"
          [label]="field.label"
          (onClick)="switchField(field)" />
      }
      <p-divider />
      <microsec-common-toolbar
        #ct
        [configuration]="filterConfiguration"
        [filterObject$]="$any(filterObject$)"
        [leftTemplate]="!!CRYPTO_ASSET_CONFIG_VALUES[selectedField].creatable ? toolbarLeftTemplate : null"
        [rightTemplate]="toolbarRightTemplate" />
      <ng-template #toolbarLeftTemplate>
        <div
          [pTooltip]="
            selectedField === CryptoAssetField.CRYPTO_ALIAS && (!cryptokens.length || !cryptoKeys.length)
              ? 'Cryptokens and Crypto Keys are required to create Crypto Aliases'
              : ''
          "
          tooltipPosition="bottom">
          <p-button
            icon="fa fa-plus"
            [rounded]="true"
            [label]="CRYPTO_ASSET_CONFIG_VALUES[selectedField].buttonLabel || 'Create'"
            [disabled]="selectedField === CryptoAssetField.CRYPTO_ALIAS && (!cryptokens.length || !cryptoKeys.length)"
            severity="primary"
            (onClick)="openAssetForm(selectedField)" />
        </div>
      </ng-template>
      <ng-template #toolbarRightTemplate>
        <p-button icon="fa fa-refresh" severity="primary" (onClick)="getCryptoAssetInfo()" />
      </ng-template>
    </div>

    <div class="col-12">
      @if (values.length > 0) {
        <ng-container
          [ngTemplateOutlet]="cryptoAssetFieldTemplate"
          [ngTemplateOutletContext]="{
            values: values,
            config: CRYPTO_ASSET_CONFIG_VALUES[selectedField]
          }" />
      } @else {
        <ng-container
          [ngTemplateOutlet]="emptyCryptoAssetTemplate"
          [ngTemplateOutletContext]="{
            field: selectedField
          }" />
      }
    </div>
    <!-- Paginator Component -->
    @if (values.length > 0) {
      <div class="col-12">
        <p-paginator
          [appendTo]="'body'"
          [dropdownAppendTo]="'body'"
          (onPageChange)="onPageChange($event)"
          [first]="page"
          [rows]="rows"
          [totalRecords]="totalRecords"
          [rowsPerPageOptions]="[10, 20, 50, 100]" />
      </div>
    }
  </div>
</microsec-loading-overlay>

<ng-template #cryptoAssetFieldTemplate let-values="values" let-config="config">
  <div class="grid">
    <div class="col-12">
      <p-accordion [multiple]="true">
        @for (info of values; track $index) {
          <p-accordionTab [selected]="isExpanded">
            <ng-template pTemplate="header">
              <span class="flex align-items-center justify-content-between gap-2 w-full">
                <span class="font-bold white-space-nowrap">{{ info.label || info.name }}</span>
                <div class="flex text-right">
                  @if (!!config?.importable && info?.destination === 'filesystem') {
                    <span
                      class="icon-button"
                      [pTooltip]="
                        !!info.fsImportable
                          ? 'Import Filesystem Key to Cryptoken'
                          : 'Cryptokens are needed to proceed to import filesystem key to cryptoken'
                      "
                      tooltipPosition="bottom"
                      appendTo="body"
                      (click)="importFSKeyToCryptoken($event, info)">
                      <i class="fa fa-arrow-right-to-bracket fa-rotate-90" [ngClass]="{ 'icon-disabled': !info.fsImportable }"></i>
                    </span>
                  }
                  @if (!!config?.deletable) {
                    <span class="icon-button ml-3" pTooltip="Delete" tooltipPosition="bottom" appendTo="body" (click)="deleteAsset($event, info)">
                      <i class="fa fa-trash"></i>
                    </span>
                  }
                </div>
              </span>
            </ng-template>
            <div class="list-fields mx-4">
              @for (field of config.properties; track $index) {
                @if (!!info[field.value]) {
                  <div class="grid align-items-center">
                    @if (field.type !== 'array') {
                      <!-- Non-array information to be displayed normally -->
                      <label class="col-3 font-bold">{{ field.label }}</label>
                      <label class="col-9 text-grey">
                        @switch (field.type) {
                          @case ('string') {
                            {{ info[field.value] || '-' }}
                          }
                          @case ('boolean') {
                            {{ !!info[field.value] ? 'Yes' : 'No' }}
                          }
                          @case ('date') {
                            {{ (info[field.value] | date: PIPE_DATETIME) || '-' }}
                          }
                          @case ('arraylist') {
                            {{ !!info[field.value].length ? info[field.value].join(', ') : '-' }}
                          }
                          @default {
                            -
                          }
                        }
                      </label>
                    } @else {
                      @if (!!info[field.value]?.length) {
                        <!-- Array information to be displayed in tabular form -->
                        <div class="w-full grid my-2">
                          <div class="col-12">
                            <label class="col-4 font-bold">{{ field.label }}</label>
                          </div>

                          <div class="col-12 ml-2">
                            <microsec-card [isTableCard]="true" [isFull]="true">
                              <microsec-common-table
                                [itemName]="field.itemName"
                                [values]="info[field.value] || []"
                                [cols]="field.extras || []"
                                [totalRecords]="info[field.value].length || 0" />
                            </microsec-card>
                          </div>
                        </div>
                      } @else {
                        <!-- Array is empty -->
                        <label class="col-4 font-bold">{{ field.label }}</label>
                        <label class="col-8 text-grey"> - </label>
                      }
                    }
                  </div>
                }
              }
            </div>
          </p-accordionTab>
        }
      </p-accordion>
    </div>
  </div>
</ng-template>

<ng-template #emptyCryptoAssetTemplate let-field="field">
  <div class="col-12 text-center mt-8">
    @switch (field) {
      @case (CryptoAssetField.CRYPTOKEN_INFO) {
        <div>
          <i [class]="'fa fa-microchip'" style="font-size: 10em; color: #333333; text-align: center"></i>
        </div>
      }
      @case (CryptoAssetField.CRYPTO_KEYS) {
        <div>
          <i [class]="'fa fa-key'" style="font-size: 10em; color: #333333; text-align: center"></i>
        </div>
      }
      @default {
        <div>
          <i [class]="'fa fa-file-lines'" style="font-size: 10em; color: #333333; text-align: center"></i>
        </div>
      }
    }
  </div>
  <div class="col-12 mb-8">
    <p class="font-bold text-center">No {{ CRYPTO_ASSET_CONFIG_VALUES[field].label }}</p>
  </div>
</ng-template>
