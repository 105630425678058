import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

// #region Third parties
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
// import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
// #endregion

// #region PrimeNG modules, services
import { ConfirmationService, MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
// #endregion

// #region Services
import { getServiceReplacements } from '@lcms-services';
import { ContextMenuService } from '@microsec/services';
// #endregion

// #region Customs
import { CustomDomainInterceptor, DEFAULT_TIMEOUT, JwtInterceptor, TimeoutInterceptor } from '@microsec/interceptors';
// #endregion

import { SharedModule } from '@lcms-shared';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// NGRX
import { NgrxLayoutModule } from '@microsec/ngrx-layout';
import { NgrxAuthenticationModule } from '@microsec/ngrx-authentication';
import { NgrxDeviceSummaryModule } from '@ngrx-device-summary';
import { NgrxDevicesModule } from '@ngrx-devices';
import { NgrxUserCertificatesModule } from '@ngrx-user-certificates';

// User Enrollment
import { ProductsModule } from '@lcms-products';
import { CheckComponent as UserEnrollmentCheckComponent } from './products/user-enrollment/check/check.component';
import { HomeComponent as UserEnrollmentHomeComponent } from './products/user-enrollment/home/home.component';
import { RequestComponent as UserEnrollmentRequestComponent } from './products/user-enrollment/request/request.component';
import { ResultComponent as UserEnrollmentResultComponent } from './products/user-enrollment/request/result/result.component';
import { UserEnrollmentComponent } from './products/user-enrollment/user-enrollment.component';

// Dynamically set the MQTT port based on the current URL port if not default to 443 (cloud env)
const currentPort = window.location.port ? parseInt(window.location.port, 10) : 443;

// TODO: Change when integrate with real MQTT broker
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  port: currentPort,
  path: '/mqtt',
  protocol: 'wss',
  connectOnCreate: false,
};

@NgModule({
  declarations: [
    AppComponent,
    // User Enrollment
    UserEnrollmentComponent,
    UserEnrollmentHomeComponent,
    UserEnrollmentRequestComponent,
    UserEnrollmentCheckComponent,
    UserEnrollmentResultComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    FontAwesomeModule,
    ProductsModule.forRoot(environment),
    SharedModule.forRoot(environment),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ContextMenuModule,
    MenuModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    TooltipModule,
    BadgeModule,
    NgrxLayoutModule,
    NgrxAuthenticationModule,
    NgrxDeviceSummaryModule,
    NgrxDevicesModule,
    NgrxUserCertificatesModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomDomainInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    // global default of 30 seconds
    { provide: DEFAULT_TIMEOUT, useValue: 300000 },
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    MessageService,
    ConfirmationService,
    ContextMenuService,
    // Service replacements
    [...getServiceReplacements(environment)],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
