import { FEATURES, PKI_MANAGEMENT_FEATURES, PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { CryptokenManagersComponent } from './cryptoken-managers/cryptoken-managers.component';
import { KmsComponent } from './keyring/kms.component';

export const KEY_MANAGEMENT_CONSTANTS = {
  CRYPTOKEN_MANAGER: {
    ROUTE: 'cryptoken-manager',
    LABEL: 'Cryptoken Manager',
  },
  KEYRING: {
    ROUTE: 'keyring',
    LABEL: 'Keyrings',
  },
};

export const KEY_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  {
    label: '',
    route: KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE,
    label: KEY_MANAGEMENT_CONSTANTS.KEYRING.LABEL,
    component: KmsComponent,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.KMS,
  },
  {
    group: PROJECT_MANAGEMENT_CONSTANTS.PROJECT_SETTINGS.ROUTE,
    route: KEY_MANAGEMENT_CONSTANTS.CRYPTOKEN_MANAGER.ROUTE,
    label: KEY_MANAGEMENT_CONSTANTS.CRYPTOKEN_MANAGER.LABEL,
    component: CryptokenManagersComponent,
    featureName: FEATURES.PKI_MANAGEMENT,
    featureCheckField: PKI_MANAGEMENT_FEATURES.CRYPTOKEN_MANAGEMENT,
  },
];
