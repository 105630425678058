import { AfterViewInit, Component, EventEmitter, Input, ViewChild } from '@angular/core';
import {
  CA_MODE_VALUES,
  COUNTRIES,
  KEYRING_TYPE_VALUES,
  MICROSEC_DEFAULT_KEYRING,
  PKI_MANAGEMENT_FEATURES,
  PROJECT_MANAGEMENT_CONSTANTS,
} from '@lcms-constants';
import { CaManagementService, KmsService, RaEnrollmentService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FormBuilderComponent } from '@microsec/components';
import { FormItem } from '@microsec/models';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { finalize } from 'rxjs';
import { ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { KEY_MANAGEMENT_CONSTANTS } from '@lcms-products';

const FORM_PARAMS = {
  COMMON_NAME: 'common_name',
  COUNTRY: 'country',
  STATE: 'state',
  LOCALITY: 'locality',
  ORGANIZATION: 'organization',
  ORGANIZATION_UNIT: 'organization_unit',
  PASSWORD: 'encryption_password',
  /** --------------------------------------------------------------------- */
  CA_SERVER_ID: 'ca_server_id',
  CA_ID: 'ca_id',
  TEMPLATE_ID: 'template_id',
  /** --------------------------------------------------------------------- */
  KEYRING_ID: 'keyring_id',
  /** --------------------------------------------------------------------- */
  ENTITY_TYPE: 'entity_type',
};

@Component({
  selector: 'app-csr-generate-form',
  templateUrl: './csr-generate-form.component.html',
  styleUrls: ['./csr-generate-form.component.scss'],
})
export class CsrGenerateFormComponent extends BaseComponent implements AfterViewInit {
  @Input() type = '';

  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  constructor(
    private caManagementSrv: CaManagementService,
    private raEnrollmentSrv: RaEnrollmentService,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.initForm();
  }

  /**
   * Initialize the form
   */
  initForm() {
    const keyringField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.KEYRING_ID,
      label: 'KMS Keyring',
      field: 'dropdown',
      options: [] as any[],
      refreshOptionsEvent: new EventEmitter<any>(),
      actionButtons: [
        {
          icon: 'fa fa-plus',
          label: 'Add New Keyring',
          styleClass: 'p-button-success',
          command: () => {
            window.open(
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}` +
                `/${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE}` +
                `/${KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE}`,
              '_blank',
            );
          },
        },
      ] as any[],
      placeholder: 'Select a keyring',
      fieldInfo: 'Select Keyring of Type FileSystem',
      defaultValue: null,
      hidden: !this.isCryptokenManagementFeatured,
      required: !!this.isCryptokenManagementFeatured,
    } as FormItem);

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COMMON_NAME,
        label: 'Common Name',
        field: 'input',
        maxLength: 64,
        fieldInfo: 'Enter common name or FQDN',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.COUNTRY,
        label: 'Country',
        field: 'dropdown',
        options: this.util.cloneObjectArray(COUNTRIES),
        filter: true,
        placeholder: 'Select a country',
        fieldInfo: 'Enter two lettered (ISO) Country code of the organization',
        required: true,
        defaultValue: 'SG',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.STATE,
        label: 'State',
        field: 'input',
        fieldInfo: 'Enter state of the organization',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LOCALITY,
        label: 'Locality',
        field: 'input',
        maxLength: 128,
        fieldInfo: 'Enter the city/town of the organization',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION,
        label: 'Organization',
        field: 'input',
        maxLength: 64,
        fieldInfo: 'Enter organization name',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ORGANIZATION_UNIT,
        label: 'Organization Unit',
        field: 'input',
        maxLength: 32,
        fieldInfo: 'Enter organization department name',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.PASSWORD,
        label: 'Password',
        field: 'password',
        maxLength: 128,
        fieldInfo: 'Enter password for the certificate',
      } as FormItem),
      /** --------------------------------------------------------------------- */
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_SERVER_ID,
        label: 'CA Server',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA server',
        fieldInfo: 'CA server',
        required: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.CA_ID,
        label: 'CA Certificate',
        field: 'dropdown',
        options: [] as any[],
        placeholder: 'Select a CA certificate',
        fieldInfo: 'CA certificate',
        required: true,
        disabled: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.TEMPLATE_ID,
        label: 'CA Template',
        options: [] as any[],
        field: 'dropdown',
        placeholder: 'Select a CA template',
        fieldInfo: 'CA template',
        disabled: true,
      } as FormItem),
      /** ----------------------------------------Keyring ID-------------------------- */
      Object.assign(new FormItem(), {
        field: 'divider',
      } as FormItem),
      keyringField,
      /** --------------------------------------------------------------------- */
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.ENTITY_TYPE,
        defaultValue: this.type,
        hidden: true,
      } as FormItem),
    ];
    keyringField.refreshOptionsEvent?.subscribe(() => {
      this.getKeyrings();
    });
    this.fields = fields;
    setTimeout(() => {
      // Setup change events
      this.setupEvents();
      setTimeout(() => {
        this.getCAConnections();
        this.getKeyrings();
      });
    }, 100);
  }

  /**
   * Submit form data
   */
  onSubmit() {
    const csrValue = this.util.cloneDeepObject(this.form.getRawValue());
    const payload = this.util.cloneDeepObject(csrValue);
    delete payload[FORM_PARAMS.CA_SERVER_ID];
    delete payload[FORM_PARAMS.CA_ID];
    delete payload[FORM_PARAMS.TEMPLATE_ID];
    if (!payload[FORM_PARAMS.PASSWORD]) {
      delete payload[FORM_PARAMS.PASSWORD];
    }
    this.form.isLoading = true;
    this.raEnrollmentSrv
      .generateCSR(csrValue[FORM_PARAMS.CA_SERVER_ID], csrValue[FORM_PARAMS.CA_ID], csrValue[FORM_PARAMS.TEMPLATE_ID], payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
          if (this.type === 'device') {
            this.router.navigate([
              `/${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                `${PROJECT_MANAGEMENT_CONSTANTS.DEVICES.ROUTE}/details`,
            ]);
          }
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.showSuccessMessage('Downloaded certificate successfully');
          const zip = new JSZip();
          if (!!rs?.certificate) {
            zip.file(`cert_${payload[FORM_PARAMS.COMMON_NAME]}.${rs?.certificate_details?.certificate_format || 'pem'}`, rs.certificate);
          }
          if (!!rs?.private_key) {
            zip.file(`private_key_${payload[FORM_PARAMS.COMMON_NAME]}.pem`, rs.private_key);
          }
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `csr_package_${payload[FORM_PARAMS.COMMON_NAME]}.zip`);
          });
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Setup change events
   */
  setupEvents() {
    this.form.setChangeEvent(FORM_PARAMS.CA_SERVER_ID, (caConnectionId) => {
      this.getCAIntermediateCertificates(caConnectionId);
    });
    this.form.setChangeEvent(FORM_PARAMS.CA_ID, (caCertId) => {
      const caTemplateField = this.fields.find((p) => p.name === FORM_PARAMS.TEMPLATE_ID);
      if (!!caTemplateField) {
        const caOptions = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID)?.options || [];
        caTemplateField.options = ((caOptions.find((p) => p.id === caCertId)?.ca_templates as any[]) || []).map((p) => ({
          ...p,
          value: p.id,
          label: p.name,
        }));
        if (!!caTemplateField.options.length) {
          this.form.enableControl(FORM_PARAMS.TEMPLATE_ID);
        } else {
          this.form.disableControl(FORM_PARAMS.TEMPLATE_ID);
        }
        this.form.setControlValue(FORM_PARAMS.TEMPLATE_ID, null);
      }
    });
  }

  /**
   * Get the list of CA connections
   */
  getCAConnections() {
    this.form.isLoading = true;
    this.caManagementSrv
      .getCAManagers(this.breadcrumbConfig?.projectId as number)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
          if (!!caServerField) {
            caServerField.options = ((rs?.data as any[]) || []).map((p) => ({
              ...p,
              value: p.id,
              label: p.name,
            }));
          }
        },
        error: (err) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get Keyrings and Populate Keyring Field on Form
   */
  getKeyrings() {
    this.form.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs) => {
          const keyringField = this.fields.find((field) => field.name === FORM_PARAMS.KEYRING_ID);
          if (!!keyringField) {
            keyringField.options = this.util.sortObjectArray(
              (
                rs?.data.filter(
                  (keyring: any) => keyring.type === KEYRING_TYPE_VALUES.FILESYSTEM && keyring.name !== MICROSEC_DEFAULT_KEYRING,
                ) as any[]
              ).map((p: any) => ({
                value: p.id,
                label: p.name,
              })),
              'label',
            );
            if (!keyringField.options.find((p) => p.value === this.form.getControlValue(FORM_PARAMS.KEYRING_ID))) {
              this.form.setControlValue(FORM_PARAMS.KEYRING_ID, null);
            }
          }
        },
        error: (err) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get the list of CA intermediate certificates
   * @param caServerId
   */
  getCAIntermediateCertificates(caServerId: any) {
    const caServerField = this.fields.find((p) => p.name === FORM_PARAMS.CA_SERVER_ID);
    const caField = this.fields.find((p) => p.name === FORM_PARAMS.CA_ID);
    if (!!caServerField && !!caField) {
      const cas: any[] = caServerField.options?.find((caServer) => caServer.value === caServerId)?.cas || [];
      const certs = cas
        .filter((p: any) => p.mode === CA_MODE_VALUES.X509)
        .filter((p: any) => (p.type as string).includes('intermediate'))
        .map((p: any) => ({
          ...p,
          value: p.id,
          label: `${p.id}: ${p.subject?.CN} (${p.description})`,
        }));
      caField.options = this.util.sortObjectArray(certs, 'id');
      // Update selected value for ca_id and ca_template_id
      this.form.enableControl(FORM_PARAMS.CA_ID);
      this.form.enableControl(FORM_PARAMS.TEMPLATE_ID);
      this.form.setControlValue(FORM_PARAMS.CA_ID, null);
      this.form.setControlValue(FORM_PARAMS.TEMPLATE_ID, null);
      if (!caField.options.length) {
        this.form.disableControl(FORM_PARAMS.CA_ID);
      }
      this.form.disableControl(FORM_PARAMS.TEMPLATE_ID);
    }
  }

  /**
   * Check if cryptographic token management is featured
   */
  get isCryptokenManagementFeatured() {
    return !!this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.CRYPTOKEN_MANAGEMENT);
  }
}
