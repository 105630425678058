import { Component, OnDestroy, OnInit } from '@angular/core';
import { USER_ROLE } from '@microsec/constants';
import { fromLayoutActions } from '@microsec/ngrx-layout';
import { BaseComponent } from '@lcms-components';
import { USER_ACCESS_CHILDREN, USER_ACCESS_CONSTANTS } from '@lcms-products';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss'],
})
export class UserAccessComponent extends BaseComponent implements OnInit, OnDestroy {
  async ngOnInit() {
    await this.prepareConfigs();
    if (!!this.checkPermissionsByScope(USER_ROLE.ADMIN, true)) {
      const tabItems = this.util
        .cloneObjectArray(USER_ACCESS_CHILDREN)
        .filter((p) => !!p.label && p.route !== USER_ACCESS_CONSTANTS.LDAP_SETTINGS.ROUTE);
      const checkedItems = tabItems.filter((p) => p.route === USER_ACCESS_CONSTANTS.USERS.ROUTE);
      checkedItems.forEach((item) => {
        const checkedItem = tabItems.find((tab) => tab.route === item.route);
        if (!!checkedItem) {
          checkedItem.visible = true;
        }
      });
      this.store.dispatch(new fromLayoutActions.UpdateTabItems(tabItems));
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  override ngOnDestroy(): void {
    this.cleanup({ shouldClearTabItems: true });
  }
}
