import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { BaseComponent } from '@lcms-components';
import { DeviceService } from '@lcms-services';
import { FormBuilderComponent } from '@microsec/components';
import { IMPORT_LABEL } from '@microsec/constants';
import { FormItem } from '@microsec/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

const FORM_PARAMS = {
  KEY_ID: 'key_id',
  LABEL: 'label',
  CRYPTOKEN_ID: 'cryptoken_id',
};

@Component({
  selector: 'app-dca-import-fs-key-form',
  templateUrl: './dca-import-fs-key-form.component.html',
  styleUrls: ['./dca-import-fs-key-form.component.scss'],
})
export class DcaImportFsKeyFormComponent extends BaseComponent implements AfterViewInit {
  fields: FormItem[] = [];

  @ViewChild('fb') form!: FormBuilderComponent;

  device: any = null;

  key: any = null;

  cryptokens: any[] = [];

  FORM_PARAMS = FORM_PARAMS;

  IMPORT_LABEL = IMPORT_LABEL;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private deviceSrv: DeviceService,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    this.device = this.dialogConfig?.data?.device;
    this.key = this.dialogConfig?.data?.key;
    this.cryptokens = this.dialogConfig?.data?.tokens || [];
    this.initForm();
  }

  /**
   * Init form
   */
  initForm() {
    const cryptokenField = Object.assign(new FormItem(), {
      name: FORM_PARAMS.CRYPTOKEN_ID,
      label: 'Cryptoken Destination',
      field: 'dropdown',
      options: this.cryptokens.map((token) => ({ label: token.label, value: token.id })),
      refreshOptionsEvent: new EventEmitter<any>(),
      placeholder: 'Select a cryptoken',
      fieldInfo: 'Select the cryptoken to store the key',
      defaultValue: this.cryptokens[0].id || null,
      required: true,
      hidden: false,
    } as FormItem);

    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        field: 'text',
        labelStyleClass: 'font-bold',
        label: 'Import a filesystem key to cryptoken.',
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.LABEL,
        label: 'Key Label',
        field: 'input',
        fieldInfo: 'Key label displayed in cryptoken',
        placeholder: 'key-1',
        required: true,
      } as FormItem),
      cryptokenField,
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.KEY_ID,
        defaultValue: this.key?.id,
        hidden: true,
      } as FormItem),
    ];
    fields.forEach((field) => field.setMediumSize());
    cryptokenField.refreshOptionsEvent?.subscribe(() => {
      this.getCryptokens();
    });
    this.fields = fields;
  }

  /**
   * Get Cryptokens and populate the dropdown options
   * @param closeDialog
   */
  getCryptokens() {
    this.form.isLoading = true;
    this.deviceSrv
      .getDeviceCryptoAssetTokens(this.device?.id)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          const cryptokens = rs?.tokens || [];
          const cryptokenField = this.fields.find((field) => field.name === FORM_PARAMS.CRYPTOKEN_ID);
          if (!!cryptokenField) {
            cryptokenField.options = cryptokens.map((token: any) => ({ label: token.label, value: token.id }));

            // Reset cryptoken field value if we cannot find the option in dropdown
            if (!cryptokenField.options.some((option) => option.value === this.form.getControlValue(FORM_PARAMS.CRYPTOKEN_ID))) {
              this.form.setControlValue(FORM_PARAMS.CRYPTOKEN_ID, null);
            }
          }
        },
        error: (err: any) => {
          this.showErrorMessage('Failed to fetch cryptokens: ' + err);
        },
      });
  }

  /**
   * Import CA certificate
   * @param closeDialog
   */
  onSubmit(closeDialog: () => void) {
    const payload = this.form.getRawValue();
    this.form.isLoading = true;
    const observable = this.deviceSrv.importDeviceCryptoAssetFSKeyToCryptoken(this.device.id, payload);

    observable
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage(`Key import is being processed. Check the events tab for details.`);
          closeDialog();
        },
        error: (err: any) => {
          this.form.showServerErrorMessage(err);
          this.showErrorMessage(err);
        },
      });
  }
}
