import { SAVE_CHANGES_LABEL, MICROSEC_LCMS_RA } from '@microsec/constants';
import { AfterViewInit, ChangeDetectorRef, Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SYSTEM_MANAGEMENT_FEATURES } from '@lcms-constants';
import { Setting2FAService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { finalize } from 'rxjs/operators';
import { FormItem } from '@microsec/models';
import { FormBuilderComponent } from '@microsec/components';

const FORM_PARAMS = {
  AUTH_PROVIDER: 'auth_provider',
  USER_LOGIN: 'user_login',
  DEVICE_ENROLLMENT: 'device_enrollment',
  EXTRA_AUTH_CONFIG: 'extra_auth_config',
};

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent extends BaseComponent implements AfterViewInit {
  FORM_PARAMS = FORM_PARAMS;

  SAVE_CHANGES_LABEL = SAVE_CHANGES_LABEL;

  globalSettingForm!: FormGroup;

  fields: FormItem[] = [];

  @ViewChild('eventTableField') eventTableField!: TemplateRef<any>;

  @ViewChild('fb') form!: FormBuilderComponent;

  setting: any = {};

  cols: any[] = [
    { field: 'event', header: 'Event Type', width: 10, frozen: true },
    { field: 'isRequired', header: '2FA Required', width: 3 },
  ];

  events: any[] = [];

  constructor(
    private setting2FASrv: Setting2FAService,
    private cd: ChangeDetectorRef,
    private injector: Injector,
  ) {
    super();
  }

  async ngAfterViewInit() {
    await this.prepareConfigs();
    const app = this.injector.get('environment')?.module as string;
    this.events = [
      { key: FORM_PARAMS.USER_LOGIN, event: 'User Login' },
      ...(app === MICROSEC_LCMS_RA ? [{ key: FORM_PARAMS.DEVICE_ENROLLMENT, event: 'Device Enrollment' }] : []),
    ];
    this.getGlobalSetting();
  }

  /**
   * Initialize form
   */
  initForm() {
    const fields: FormItem[] = [
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.AUTH_PROVIDER,
        label: 'Select your preferred authentication provider:',
        labelStyleClass: 'font-bold',
        field: 'radio',
        options: this.authProviderOptions,
        layout: 'vertical',
        defaultValue: this.setting[FORM_PARAMS.AUTH_PROVIDER] || null,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.EXTRA_AUTH_CONFIG,
        field: 'custom',
        customField: this.eventTableField,
        hasNoLabel: true,
        required: false,
        hidden: this.setting[FORM_PARAMS.AUTH_PROVIDER] === null || (!this.isAuthAuthenticatorAppFeatured && !this.isAuthRSASecurIDFeatured),
      }),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.USER_LOGIN,
        defaultValue: this.setting[FORM_PARAMS.USER_LOGIN],
        hidden: true,
      } as FormItem),
      Object.assign(new FormItem(), {
        name: FORM_PARAMS.DEVICE_ENROLLMENT,
        defaultValue: this.setting[FORM_PARAMS.DEVICE_ENROLLMENT],
        hidden: true,
      } as FormItem),
    ];
    fields.forEach((field) => field.setFullSize());
    this.fields = fields;
    this.setupChangeEvents();

    // Set default option to null if both features are not enabled, at initialization
    if (!this.isAuthAuthenticatorAppFeatured && !this.isAuthRSASecurIDFeatured) {
      this.form.setControlValue(FORM_PARAMS.AUTH_PROVIDER, null);
      this.setUserLoginAndDeviceEnrollmentToFalse();
    }
  }

  /**
   * Handle Form Field Change Events
   */
  setupChangeEvents() {
    this.form.setChangeEvent(FORM_PARAMS.AUTH_PROVIDER, (value: any[]) => {
      this.form.setControlVisibility(FORM_PARAMS.EXTRA_AUTH_CONFIG, !!value);
      if (value === null) {
        this.setUserLoginAndDeviceEnrollmentToFalse();
      }
    });
    this.cd.detectChanges();
  }

  setUserLoginAndDeviceEnrollmentToFalse() {
    this.form.setControlValue(FORM_PARAMS.USER_LOGIN, false);
    this.form.setControlValue(FORM_PARAMS.DEVICE_ENROLLMENT, false);
  }

  /**
   * Get global setting
   */
  getGlobalSetting() {
    this.form.isLoading = true;
    this.setting2FASrv
      .getGlobalSetting()
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.setting = response;
          this.initForm();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Submit form
   */
  onSubmit() {
    this.form.isLoading = true;
    const payload = this.form.getValue();
    payload.is_enabled = !!payload[FORM_PARAMS.AUTH_PROVIDER];
    delete payload[FORM_PARAMS.EXTRA_AUTH_CONFIG];
    this.setting2FASrv
      .updateGlobalSetting(payload)
      .pipe(
        finalize(() => {
          this.form.isLoading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.showSuccessMessage('Saved 2FA Configuration successfully');
          this.getGlobalSetting();
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * return authentication provider options to form
   */
  private get authProviderOptions() {
    return [
      this.isAuthAuthenticatorAppFeatured || this.isAuthRSASecurIDFeatured
        ? { label: 'Disabled', value: null }
        : { label: 'Disabled (Not included in the licensing plan)', value: null },
      this.isAuthAuthenticatorAppFeatured ? { label: 'Authenticator App (e.g. Google Authenticator)', value: 'google_auth' } : null,
      this.isAuthRSASecurIDFeatured ? { label: 'RSA SecurID', value: 'rsa' } : null,
    ].filter((option) => option !== null);
  }

  /**
   * Check if authenticatorApp is featured
   */
  get isAuthAuthenticatorAppFeatured() {
    return this.checkSystemManagementFeatureEnabled(SYSTEM_MANAGEMENT_FEATURES.AUTH_AUTHENTICATOR_APP);
  }

  /**
   * Check if Auth RSA SecurID is featured
   */
  get isAuthRSASecurIDFeatured() {
    return this.checkSystemManagementFeatureEnabled(SYSTEM_MANAGEMENT_FEATURES.AUTH_RSA_SECURID);
  }
}
