export const DEVICE_OVERVIEW_CONSTANTS = {
  GENERAL: {
    ROUTE: 'general',
    LABEL: 'General',
  },
  SECURITY: {
    ROUTE: 'security',
    LABEL: 'Security',
  },
  CRYPTO_ASSETS: {
    ROUTE: 'crypto-assets',
    LABEL: 'Crypto Assets',
  },
};

export const BANNER_CHECKBOX_TYPE_VALUES = {
  CA: 'ca',
  DEVICE: 'device',
  MICROAGENT: 'microagent',
} as const;

export const HIDE_OVERVIEW_BANNER_KEY = 'hideOverviewBanner_ra' as const;
