import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Util } from '@microsec/utilities';
import moment from 'moment';
import { catchError, map, Observable, throwError } from 'rxjs';
import { API } from '../api';
import { IDeviceCertificateService } from './IDeviceCertificateService';

const API_CERTIFICATES = `${API.CTMS}/certificates`;

@Injectable({
  providedIn: 'root',
})
export class DeviceCertificateService implements IDeviceCertificateService {
  constructor(private http: HttpClient) {}

  getCertificates(organizationId: any, projectId: any, kmsKeyId?: any, kmsKeyringId?: any): Observable<any> {
    const params = [];
    if (!!organizationId) {
      params.push(`organization_id=${organizationId}`);
    }
    if (!!projectId) {
      params.push(`project_id=${projectId}`);
    }
    if (!!kmsKeyId) {
      params.push(`kms_key_id=${kmsKeyId}`);
    }
    if (!!kmsKeyringId) {
      params.push(`kms_keyring_id=${kmsKeyringId}`);
    }
    return this.http.get<any[]>(`${API_CERTIFICATES}${!!params ? `?${params.join('&')}` : ''}`).pipe(
      map((rs: any) => {
        const certificates = Util.sortObjectArray(rs.certificates || [], 'id');
        certificates.forEach((cert) => {
          cert.not_before = !!cert.not_before ? moment.utc(cert.not_before).local() : null;
          cert.not_after = !!cert.not_after ? moment.utc(cert.not_after).local() : null;
        });
        rs.certificates = certificates;
        return rs;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  getTotalCertificates(organizationId: any, projectId: any): Observable<any> {
    const params = [];
    if (!!organizationId) {
      params.push(`organization_id=${organizationId}`);
    }
    if (!!projectId) {
      params.push(`project_id=${projectId}`);
    }
    return this.http.get<any[]>(`${API_CERTIFICATES}${!!params ? `?${params.join('&')}` : ''}`).pipe(
      map((rs: any) => {
        return ((rs?.certificates as any[]) || []).length;
      }),
      catchError((error: HttpErrorResponse) => throwError(() => error)),
    );
  }

  getCertificate(certId: any): Observable<any> {
    return this.http.get<any>(`${API_CERTIFICATES}/${certId}`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  updateCertificate(certId: any, payload: any): Observable<any> {
    return this.http
      .put<any>(`${API_CERTIFICATES}/${certId}/status`, payload)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  getCertificatePrivateKey(certId: any): Observable<any> {
    return this.http.get<any>(`${API_CERTIFICATES}/${certId}/private_key`).pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  dowloadCertificate(certId: any, hasPrivateKey = false): Observable<any> {
    const options: any = { responseType: 'blob' };
    return this.http
      .get<any>(`${API_CERTIFICATES}/${certId}/zip?private_key=${hasPrivateKey}`, options)
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
