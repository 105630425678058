import { CA_MANAGER_TYPE_VALUES, PKI_MANAGEMENT_FEATURES } from '@lcms-constants';
import { Component, ElementRef, OnInit } from '@angular/core';
import { CaManagementService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { FORCE_DELETE_LABEL, FORCE_REMOVE_MESSAGE_TEMPLATE, REMOVE_LABEL } from '@microsec/constants';
import { ActionMenuItem, ConfirmationDialogConfig } from '@microsec/models';
import { finalize } from 'rxjs/operators';
import { CaConnectionFormComponent } from './ca-connection-form/ca-connection-form.component';
const FIELDS = {
  ID: 'ID',
  CA_SERVER_NAME: 'CA Server Name',
  TYPE: 'Type',
  ADDRESS: 'Address',
  STATUS: 'Status',
};

@Component({
  selector: 'app-connected-cas',
  templateUrl: './connected-cas.component.html',
  styleUrls: ['./connected-cas.component.scss'],
})
export class ConnectedCAsComponent extends BaseComponent implements OnInit {
  isLoading = false;

  cols: any[] = [
    { field: 'id', header: FIELDS.ID, width: 5, frozen: true },
    { field: 'name', header: FIELDS.CA_SERVER_NAME, width: 12 },
    { field: 'type', header: FIELDS.TYPE, width: 10 },
    { field: 'address', header: FIELDS.ADDRESS, width: 10 },
    { field: 'connection_status', header: FIELDS.STATUS, width: 10 },
  ];

  selectedCAManager: any = null;

  values: any[] = [];

  toolbarActionButtons: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  constructor(
    public el: ElementRef,
    private caManagementSrv: CaManagementService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.actionMenuItems = [
      {
        label: 'Edit',
        icon: 'fas fa-edit',
        visible: ({ rowData }: any) => {
          return rowData.type !== CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA;
        },
        command: ({ rowData }: any) => {
          this.openCAManagerForm(rowData);
        },
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        command: ({ rowData }: any) => this.confirmDeletion(rowData),
      },
    ];
    this.getConnectedCAs();
  }

  /**
   * Unselect CA manager
   * @param event
   * @param datatable
   * @returns
   */
  unselectedCAManager(event: any, datatable: any) {
    if (event?.target !== datatable) {
      return;
    }
    this.selectedCAManager = null;
  }

  /**
   * Get the list of connected CAs
   */
  getConnectedCAs() {
    this.isLoading = true;
    this.caManagementSrv
      .getCAManagers(this.breadcrumbConfig?.projectId as number)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.values = rs?.data as any[];
          if (!!this.selectedCAManager) {
            this.selectedCAManager = this.values.find((p) => p.id === this.selectedCAManager.id);
          }

          // Remove add acion button with the below conditions (based on licensing)
          const showAddCAConnectionButton =
            !(this.values.some((ca) => ca.type === CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA) && !!this.isX509Featured) ||
            !!this.isExternalCAFeatured;
          this.toolbarActionButtons = !!showAddCAConnectionButton
            ? [
                {
                  label: 'Connect to CA',
                  severity: 'primary',
                  icon: 'fas fa-plus',
                  callback: () => this.openCAManagerForm(),
                },
              ]
            : [];
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Open connected CA form for create/edit
   * @param caManager
   */
  openCAManagerForm(caManager?: any) {
    const hasInBuiltCa = this.values.some((ca) => ca.type === CA_MANAGER_TYPE_VALUES.MICROSEC_IN_BUILT_CA);
    const dialogRef = this.dialogSrv.open(CaConnectionFormComponent, {
      data: { caManager, hasInBuiltCa },
      header: `${!caManager ? 'Connect to CA' : 'Edit CA Connection'}`,
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialogRef.onClose.subscribe((res) => {
      if (res) {
        this.getConnectedCAs();
      }
    });
  }

  /**
   * Confirm CA connection removal
   * @param caManager
   */
  confirmDeletion(caManager?: any) {
    const successFn = () => {
      this.showSuccessMessage(`Deleted CA connection ${caManager.name} successfully`);
      this.selectedCAManager = null;
      this.getConnectedCAs();
    };
    // Normal confirmation
    this.confirm({
      action: REMOVE_LABEL,
      objectName: 'CA Connection',
      object: caManager,
      objectFieldName: 'name',
      acceptRequest: this.caManagementSrv.deleteCAManager(caManager.id),
      next: () => {
        successFn();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
        // Force confirmation
        setTimeout(() => {
          this.confirm({
            isDependenciesDetected: true,
            action: FORCE_DELETE_LABEL,
            customContent: FORCE_REMOVE_MESSAGE_TEMPLATE.replace('{0}', 'CA connection'),
            shouldShowDefaultContent: false,
            acceptRequest: this.caManagementSrv.deleteCAManager(caManager.id, true),
            next: () => {
              successFn();
            },
            error: (err: any) => {
              this.showErrorMessage(err);
            },
          } as ConfirmationDialogConfig);
        });
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Refresh the selected connected CA
   * @param managerId
   */
  refreshConnectedCA(managerId: any) {
    this.isLoading = true;
    this.caManagementSrv
      .getCAManagers(managerId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (caManager: any) => {
          this.values = this.util.cloneObjectArray(this.values).map((value) => {
            if (value?.id === caManager?.id) {
              value = caManager?.data;
            }
            return value;
          });
          if (!!managerId) {
            this.selectedCAManager = this.values.find((p) => p.id === managerId);
          }
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Check if feature for X509 enabled
   */
  get isX509Featured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.X509);
  }

  /**
   * Check if feature for external CA enabled
   */
  get isExternalCAFeatured() {
    return this.checkPKIManagementFeatureEnabled(PKI_MANAGEMENT_FEATURES.EXTERNAL_CA);
  }
}
