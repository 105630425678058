import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { KmsService, RepositoryService } from '@lcms-services';
import { BaseComponent } from '@lcms-components';
import { ActionMenuItem, ConfirmationDialogConfig } from '@microsec/models';
import { DELETE_LABEL, ORGANIZATION_LEVEL_ROUTE, PROJECT_LEVEL_ROUTE } from '@microsec/constants';
import { BehaviorSubject, finalize, forkJoin } from 'rxjs';
import { PackageFormComponent } from './package-form/package-form.component';
import { CommonTableComponent } from '@microsec/components';
import { PROJECT_MANAGEMENT_CONSTANTS } from '@lcms-constants';
import { KEY_MANAGEMENT_CONSTANTS } from '@lcms-products';
import { MICROSEC_MICROAGENT_REPO } from '../../../repository-management.constants';

const FIELDS = {
  NAME: 'Name',
  VERSION: 'Version',
};

@Component({
  selector: 'app-repository-packages',
  templateUrl: './repository-packages.component.html',
  styleUrls: ['./repository-packages.component.scss'],
})
export class RepositoryPackagesComponent extends BaseComponent implements OnInit {
  isLoading = false;

  _selectedRepo: any = null;

  get selectedRepo() {
    return this._selectedRepo;
  }

  @Input() set selectedRepo(value: any) {
    this._selectedRepo = value;
    if (!!this.selectedPackage) {
      this.selectedPackage = null;
    }
    this.getPackagesByRepository();
  }

  @Output() refreshRepositoriesEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dt') dt!: CommonTableComponent;

  toolbarActionButtons: any[] = [
    {
      severity: 'primary',
      label: 'Upload Package',
      icon: 'pi pi-plus',
      callback: () => {
        this.openPackageForm();
      },
    },
  ];

  cols = [
    { field: 'name', header: FIELDS.NAME, width: 10 },
    { field: 'version', header: FIELDS.VERSION, width: 10 },
  ];

  keyrings: any[] = [];

  _selectedPackage: any = null;

  get selectedPackage() {
    return this._selectedPackage;
  }

  @Input() set selectedPackage(value: any) {
    this._selectedPackage = value;
    this._selectedPackage.signingActive = !!value?.signing ? 'Yes' : 'No';
    this.selectedPackage$.next(this._selectedRepo);
  }

  selectedPackage$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  values: any[] = [];

  actionMenuItems: ActionMenuItem[] = [];

  structure: any[] = [];

  constructor(
    private httpClient: HttpClient,
    private repositorySrv: RepositoryService,
    private kmsSrv: KmsService,
  ) {
    super();
  }

  async ngOnInit() {
    await this.prepareConfigs();
    this.actionMenuItems = [
      {
        label: 'Download',
        icon: 'fas fa-download',
        command: ({ rowData }: any) => this.downloadPackage(rowData),
      },
      {
        label: 'Delete',
        icon: 'fas fa-trash',
        visible: () => !!this.permissions[this.SCOPE.ORGANIZATION][this.USER_ROLE.ADMIN] && this.selectedRepo?.name !== MICROSEC_MICROAGENT_REPO,
        command: ({ rowData }: any) => this.openDeleteConfirmation(rowData),
      },
    ];
    this.selectedPackage$.asObservable().subscribe(() => {
      if (!!this.selectedPackage?.signing && !!this.selectedPackage?.keyring_id && !!this.selectedPackage?.key_id) {
        this.getKeyringAndGPGKey(this.selectedPackage.keyring_id, this.selectedPackage.key_id);
      } else {
        this.initPackageForm();
      }
    });
    this.getPackagesByRepository();
  }

  /**
   * Init package form
   */
  initPackageForm() {
    this.structure = [
      { label: 'Name', name: 'name' },
      { label: 'Version', name: 'version' },
      { label: 'Architecture', name: 'architecture' },
      { label: 'Distribution', name: 'distribution' },
      { label: 'Signing Active', name: 'signingActive' },
      ...(!!this.selectedPackage?.signing
        ? [
            { label: 'Keyring', name: 'keyring', field: this.selectedPackage?.keyring ? 'linkchips' : 'label' },
            { label: 'GPG Key', name: 'gpgKey', field: this.selectedPackage?.gpgKey ? 'linkchips' : 'label' },
          ]
        : []),
    ];
  }

  /**
   * Get keyring and GPG key
   * @param keyringId
   * @param gpgKeyId
   */
  getKeyringAndGPGKey(keyringId: any, gpgKeyId: any) {
    forkJoin([this.kmsSrv.getKeyring(keyringId), this.kmsSrv.getGPGKey(keyringId, gpgKeyId)])
      .pipe(
        finalize(() => {
          this.initPackageForm();
        }),
      )
      .subscribe({
        next: (rs: any[]) => {
          this._selectedPackage.keyring = [
            {
              label: `${rs?.[0]?.data?.name || rs?.[0]?.name || keyringId} (${keyringId})`,
              url:
                `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE}` +
                `/${KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE}` +
                `?keyring_id=${keyringId}`,
            },
          ];
          this._selectedPackage.gpgKey = [
            {
              label: `${rs?.[1]?.label || gpgKeyId} (${gpgKeyId})`,
              url:
                `${ORGANIZATION_LEVEL_ROUTE}/${this.breadcrumbConfig?.organizationId}/` +
                `${PROJECT_LEVEL_ROUTE}/${this.breadcrumbConfig?.projectId}/` +
                `/${PROJECT_MANAGEMENT_CONSTANTS.KEY_MANAGEMENT.ROUTE}` +
                `/${KEY_MANAGEMENT_CONSTANTS.KEYRING.ROUTE}` +
                `?keyring_id=${keyringId}&gpg_key_id=${gpgKeyId}`,
            },
          ];
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Get packages by repository
   */
  getPackagesByRepository() {
    this.isLoading = true;
    this.subscriptions.forEach((s) => s.unsubscribe());
    const subscription = this.repositorySrv
      .getPackagesByRepository(this.selectedRepo.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.values = rs?.packages as any[];
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Open package form
   */
  openPackageForm() {
    const dialog = this.dialogSrv.open(PackageFormComponent, {
      data: {
        repository: this.selectedRepo,
      },
      header: 'Upload Package',
      width: '800px',
      height: 'min-content',
      closeOnEscape: true,
    });
    dialog.onClose.subscribe((rs) => {
      if (!!rs) {
        this.getPackagesByRepository();
        this.refreshRepositoriesEvent.emit();
      }
    });
  }

  /**
   * Get keyring
   */
  getKeyrings() {
    this.isLoading = true;
    this.kmsSrv
      .getKeyrings(this.breadcrumbConfig?.projectId)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (rs: any) => {
          this.keyrings = rs?.data as any[];
        },
        error: (err: any) => {
          this.showErrorMessage(err);
        },
      });
  }

  /**
   * Download package
   * @param pkg
   */
  downloadPackage(pkg: any) {
    this.util
      .downloadFileFromServer(this.httpClient, `repo-services/v1/repositories/${this.selectedRepo.id}/packages/${pkg.id}`)
      .then(() => {
        this.showSuccessMessage('Downloaded package successfully');
      })
      .catch((e) => {
        this.showErrorMessage(e);
      });
  }

  /**
   * Confirm deletion
   * @param pkg
   */
  openDeleteConfirmation(pkg: any) {
    this.confirm({
      action: DELETE_LABEL,
      objectName: 'Package',
      object: pkg,
      objectFieldName: 'name',
      acceptRequest: this.repositorySrv.deletePackageByRepository(this.selectedRepo.id, pkg.id),
      next: () => {
        this.showSuccessMessage(`Deleted package ${pkg.name} successfully`);
        this.getPackagesByRepository();
        this.refreshRepositoriesEvent.emit();
      },
      error: (err: any) => {
        this.showErrorMessage(err);
      },
    } as ConfirmationDialogConfig);
  }

  /**
   * Get signing key name
   * @param keyId
   * @returns
   */
  getSigningKeyName(keyId: any) {
    return this.keyrings.find((p) => p.id === keyId)?.name;
  }
}
