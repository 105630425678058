<microsec-loading-overlay [isLoading]="!!isLoading">
  <div class="container-border">
    @if (!!permissions[SCOPE.PROJECT][USER_ROLE.READ_ONLY]) {
      <microsec-details-panel [title]="selectedDevice?.common_name" [buttons]="actionButtons" (collapseEvent)="unselectDevice()">
        <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex" styleClass="microsec-dashboard-tab">
          <p-tabPanel header="Overview">
            <ng-template pTemplate="content">
              <microsec-scroller [maxHeight]="maxHeight">
                <app-device-overview [labels]="labels" />
              </microsec-scroller>
            </ng-template>
          </p-tabPanel>
          @if (!!isTabEnabled && !!checkFeature('telemetry')) {
            <p-tabPanel header="Telemetry">
              <ng-template pTemplate="content">
                <app-device-telemetry [selectedDevice]="selectedDevice" />
              </ng-template>
            </p-tabPanel>
          }
          @if (!!isTabEnabled && !!checkFeature('packages')) {
            <p-tabPanel header="Packages">
              <ng-template pTemplate="content">
                <app-device-packages (devicePackagesEvent)="hasPackages = $event" />
              </ng-template>
            </p-tabPanel>
          }
          @if (!!isTabEnabled && !!checkFeature('firmware')) {
            <p-tabPanel header="Firmware">
              <ng-template pTemplate="content">
                <microsec-scroller [maxHeight]="maxHeight">
                  <app-device-firmware />
                </microsec-scroller>
              </ng-template>
            </p-tabPanel>
          }
          @if (!!isTabEnabled && !!checkFeature('firewalls')) {
            <p-tabPanel header="Rulesets">
              <ng-template pTemplate="content">
                <app-device-rules [selectedDevice]="selectedDevice" />
              </ng-template>
            </p-tabPanel>
          }
          @if (!!isTabEnabled && !!checkFeature('keys')) {
            <p-tabPanel header="Crypto Assets">
              <ng-template pTemplate="content">
                <app-device-crypto-assets />
              </ng-template>
            </p-tabPanel>
          }
          <p-tabPanel header="Events">
            <ng-template pTemplate="content">
              <app-shared-events [isMainPage]="false" [deviceId]="selectedDevice.id" />
            </ng-template>
          </p-tabPanel>
          @if (!!isTabEnabled && !!checkFeature('telemetry') && !!selectedDevice.is_virtual) {
            <p-tabPanel header="Administration">
              <ng-template pTemplate="content">
                <microsec-scroller [maxHeight]="maxHeight">
                  <app-device-administration />
                </microsec-scroller>
              </ng-template>
            </p-tabPanel>
          }
        </p-tabView>
      </microsec-details-panel>
    }
  </div>
</microsec-loading-overlay>
