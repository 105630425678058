import { faTags } from '@fortawesome/free-solid-svg-icons';
import { ORGANIZATION_MANAGEMENT_CHILDREN as COMMON_ORGANIZATION_MANAGEMENT_CHILDREN } from '@lcms-products';
import { ORGANIZATION_MANAGEMENT_CONSTANTS, ORGANIZATION_MANAGEMENT_GROUPS } from '@lcms-constants';
import { ILayoutItem } from '@microsec/interfaces';
import { SharedLabelsComponent } from '../shared-components/shared-labels/shared-labels.component';

export const ORGANIZATION_MANAGEMENT_CHILDREN: ILayoutItem[] = [
  ...COMMON_ORGANIZATION_MANAGEMENT_CHILDREN,
  // hide envelope tab for now
  // {
  //   group: ORGANIZATION_MANAGEMENT_GROUPS.OVERVIEW,
  //   route: ORGANIZATION_MANAGEMENT_CONSTANTS.ENVELOPES.ROUTE,
  //   icon: faEnvelope,
  //   label: ORGANIZATION_MANAGEMENT_CONSTANTS.ENVELOPES.LABEL,
  //   component: EnvelopeManagementComponent,
  //   children: ENVELOPES_CHILDREN,
  //   featureName: FEATURES.SUPPLY_CHAIN_MANAGEMENT,
  //   featureCheckField: SUPPLY_CHAIN_MANAGEMENT_FEATURES.ENVELOPES,
  //   visible: false,
  // },
  {
    group: ORGANIZATION_MANAGEMENT_GROUPS.OVERVIEW,
    route: ORGANIZATION_MANAGEMENT_CONSTANTS.LABELS.ROUTE,
    icon: faTags,
    label: ORGANIZATION_MANAGEMENT_CONSTANTS.LABELS.LABEL,
    component: SharedLabelsComponent,
    visible: false,
  },
];
